// React and Router
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Firebase
import { auth, updateUserEmail } from "../../firebase.js"

// Components
import Header from "../shared/Header"

// Stripe
import getStripe from "../stripe/GetStripe.js";

// Styles
import "../../css/Account.css"
import "../../css/Create.css"

function Account() {

        const { uid } = useParams()
        const [accountEmail, setAccountEmail] = useState("")
        const [accountName, setAccountName] = useState("")
        const [accountCreatedDate, setAccountCreatedDate] = useState("")
        const [accountLastLoginDate, setAccountLastLoginDate] = useState("")
        const [accountVipStatus, setAccountVipStatus] = useState(false)
        const [accountSubscriberStatus, setAccountSubscriberStatus] = useState(false)
        const [accountTheme, setAccountTheme] = useState(() => {
                return localStorage.getItem("theme") || "Default";
        });
        const navigate = useNavigate()
        const url = window.location.pathname.split('/').pop();

        const [customLink, setCustomLink] = useState("")
        const [customLinkTitle, setCustomLinkTitle] = useState("")
        const [discordLink, setDiscordLink] = useState("")
        const [linkedInLink, setLinkedInLink] = useState("")
        const [youtubeLink, setYoutubeLink] = useState("")
        const [githubLink, setGithubLink] = useState("")
        const [twitterLink, setTwitterLink] = useState("")

        useEffect(() => {
                const query = new URLSearchParams(window.location.search);
                const sessionId = query.get("session_id");
                if (sessionId) {
                        verifySession(sessionId);
                }
        }, [url]);

        const handleSelectedTheme = (e) => {
                const newTheme = e.target.value;
                setAccountTheme(newTheme);
                updateUserTheme(newTheme);
        }

        useEffect(() => {
                if (uid) {
                        fetchAccountDetails()
                }
        }, [uid])

        useEffect(() => {
                const theme = localStorage.getItem('theme') || 'Default';
                const root = document.documentElement;
        
                if (theme === 'Dark Theme') {
                        root.style.setProperty('--title-color', '#3f66a0')
                        root.style.setProperty('--main-content-color', '#5B92E5')
                        root.style.setProperty('--main-content-bg-color', '#090e16')
                        root.style.setProperty('--read-only-field', '#243a5b')
                        root.style.setProperty('--bg-subscriber-button', '#5B92E5')
                        root.style.setProperty("--placeholder-color", "#6b9ce7")
                }

                if (theme === 'Purple Theme') {
                        root.style.setProperty('--title-color', '#800080')
                        root.style.setProperty('--main-content-color', '#800080')
                        root.style.setProperty('--main-content-bg-color', '#f2e5f2')
                        root.style.setProperty('--read-only-field', '#a64ca6')
                        root.style.setProperty('--bg-subscriber-button', '#800080')
                        root.style.setProperty("--placeholder-color", "#993299")
                        root.style.setProperty('--title-section-bg-color', '#190019')
                        root.style.setProperty('--header-bg-color', '#190019')
                }
        }, []);

        const formatTimestamp = (timestamp) => {
                const date = new Date(timestamp);
                return date.toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                });
        };

        const fetchAccountDetails = async () => {
                try {
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/users/${uid}/`)
                        const data = await response.json()

                        console.log(data)

                        if (response.ok) {
                                setAccountEmail(data.email)
                                setAccountName(data.name)
                                setAccountCreatedDate(formatTimestamp(data.created_at))
                                setAccountLastLoginDate(formatTimestamp(data.last_login))
                                setAccountVipStatus(data.is_vip)
                                setAccountSubscriberStatus(data.is_subscriber)
                                setYoutubeLink(data.youtube_channel)
                                setCustomLink(data.custom_domain)
                                setCustomLinkTitle(data.custom_domain_title)
                                setDiscordLink(data.discord_server)
                                setLinkedInLink(data.linkedin_link)
                                setGithubLink(data.github_link)
                                setTwitterLink(data.twitter_link)
                        }

                } catch (error) {
                        console.error("Error in fetching account details: ", error)
                }
        }

        const saveAccountName = async () => {
                try {
                        const data = {
                                name: accountName
                        }

                        const user = auth.currentUser;
                        const token = user ? await user.getIdToken() : null;
                
                        if (!token) {
                                console.error("User is not authenticated.");
                                return;
                        }

                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/users/update/${uid}/`, {
                                method: "PATCH",
                                headers: { 
                                        "Content-Type": "application/json",
                                        "auth-token": `Bearer ${token}`,
                                },
                                body: JSON.stringify(data)
                        })

                        if (response.ok) {
                                alert("Account name changed successfully!")
                        } else {
                                alert("Failed to update account name.")
                        }
                } catch (error) {
                        alert("Failed to change account name: ", error)
                }
        }

        const saveAccountEmail = async () => {
                try {
                        const data = {
                                email: accountEmail,
                                accountUid: uid
                        }
                        const user = auth.currentUser;
                        const token = user ? await user.getIdToken() : null;
                        if (!token) {
                                console.error("User is not authenticated.");
                                return;
                        }
                        await updateUserEmail(accountEmail);
                        alert("A verification email has been sent. Please confirm the change before logging in again.");
                } catch (error) {
                        alert("Failed to change account email.")
                }
        }

        const saveCustomLink = async () => {
                try {
                        const data = {
                                customLink: customLink,
                                customLinkTitle: customLinkTitle,
                                twitterLink: twitterLink,
                                discordLink: discordLink,
                                linkedInLink: linkedInLink,
                                githubLink: githubLink,
                                youtubeLink: youtubeLink,
                        }
                        const user = auth.currentUser;
                        const token = user ? await user.getIdToken() : null;
                        if (!token) {
                                console.error("User is not authenticated.");
                                return;
                        }
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/users/update/${uid}/link/`, {
                                method: "PATCH",
                                headers: { 
                                        "Content-Type": "application/json",
                                        "auth-token": `Bearer ${token}`,
                                },
                                body: JSON.stringify(data)
                        })
                        if (response.ok) {
                                alert("Custom link saved successfully!")
                        } else {
                                alert("Failed to save custom link.")
                        }
                } catch (error) {
                        alert("Failed to save custom link: ", error)
                }
        }

        const updateAccountSubscriberStatus = async (isSubscriber) => {
                try {

                        const data = {
                                isSubscriber: isSubscriber
                        }

                        const user = auth.currentUser;
                        const token = user ? await user.getIdToken() : null;
                
                        if (!token) {
                                console.error("User is not authenticated.");
                                return;
                        }

                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/users/${uid}/subscriber-status/`, {
                                method: "PATCH",
                                headers: { 
                                        "Content-Type": "application/json",
                                        "auth-token": `Bearer ${token}`,
                                },
                                body: JSON.stringify(data)
                        })

                        if (response.ok) {
                                navigate(`/account/${uid}`)
                                window.location.reload()
                                alert("Subscriber status changed successfully!")
                        } else {
                                alert("Failed to update subscriber status. Please contact us immediately if you've received any unexpected charges from us.")
                        }
                } catch (error) {
                        alert("Failed to update subscriber status. Please contact us immediately if you've received any unexpected charges from us. Error: ", error)
                }
        }

        const updateUserTheme = (newTheme) => {
                localStorage.setItem('theme', newTheme);
                window.location.reload()
        }

        const handleSubscription = async () => {
                const stripe = await getStripe();
                const user = auth.currentUser;

                if (!user) {
                        alert("You need to be logged in to subscribe.");
                        return;
                }

                const token = await user.getIdToken();
                const firebaseUid = user.uid;  
            
                const result = await stripe.redirectToCheckout({
                        lineItems: [
                                {
                                        price: 'price_1QYH7T2NcK2IZo0y8lmoSX1T',
                                        quantity: 1,
                                },
                        ],
                        mode: 'subscription',
                        successUrl: `${window.location.origin}/account/${uid}?session_id={CHECKOUT_SESSION_ID}`,
                        cancelUrl: `${window.location.origin}/account?canceled=true`,
                });
            
                if (result.error) {
                        console.error('Error redirecting to checkout:', result.error.message);
                }
        };

        const verifySession = async (sessionId) => {
                try {
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/verify-checkout-session/${sessionId}/${uid}/`);
                        // const response = await fetch(`http://localhost:8000/verify-checkout-session/${sessionId}/`);
                        const data = await response.json();
                        if (data.payment_status === "paid") {
                                alert("Thank you for subscribing!")
                        } else {
                                alert("Payment not completed.");
                        }
                } catch (error) {
                        console.error("Error verifying session:", error);
                }
        };

        function handleManageSubscriptionClick() {
                const url = "https://billing.stripe.com/p/login/aEU5mr1XU6fjcWAcMM"; 
                window.open(url, "_blank");
        }

        return (
                <div>
                        <Header />
                        <div className="Account__title-section">
                                <h1>Account Settings</h1>
                        </div>
                        <div className="Account__main-content">

                                <h2>Account Details</h2>

                                <p className="small" style={{ paddingTop: "30px" }}>Account Created on: {accountCreatedDate}</p>
                                <p className="small" style={{ paddingBottom: "30px" }}>Last Logged in: {accountLastLoginDate}</p>

                                <label>Your Displayed Name: </label>
                                <input
                                        id="accountNameInput"
                                        type="text"
                                        value={accountName}
                                        onChange={(e) => setAccountName(e.target.value)}
                                        onBlur={() => {
                                                if (accountName.trim()) {
                                                        saveAccountName();
                                                }
                                        }}
                                        className="Account__edit-input"
                                />
                                {/* <p style={{ marginTop: "30px" }}>Email Address: <span title="As of the current release, account emails can't be changed. :(" className="Account__read-only-field">{accountEmail}</span></p> */}
                                <br></br>
                                <label style={{ marginTop: "20px" }}>Email Address: </label>
                                <input
                                        id="accountEmail"
                                        type="email"
                                        value={accountEmail}
                                        onChange={(e) => setAccountEmail(e.target.value)}
                                        onBlur={() => {
                                                const user = auth.currentUser; // Get current Firebase user
                                                if (user && user.email && accountEmail.trim() && accountEmail.trim() !== user.email) {
                                                        saveAccountEmail(); // Only call if new email is different
                                                }
                                        }}
                                        className="Account__edit-input"
                                />

                                {accountVipStatus ? (
                                        <div style={{ marginTop: "20px" }}>
                                                <select
                                                        className="form-select Account__input"
                                                        aria-label="Default select example"
                                                        name="account_theme"
                                                        value={accountTheme}
                                                        onChange={handleSelectedTheme}
                                                        required
                                                >
                                                        <option value="Default">Default (No Theme)</option>
                                                        <option value="Dark Theme">Dark Theme</option>
                                                        <option value="Gray Theme">Gray Theme</option>
                                                        <option value="Dark Gray Theme">Dark Gray Theme</option>
                                                        <option value="Purple Theme">Purple Theme</option>
                                                        <option value="Dark Purple Theme">Dark Purple Theme</option>
                                                </select>
                                        </div>
                                ) : accountSubscriberStatus ? (
                                        <div style={{ marginTop: "40px" }}>
                                                <select
                                                        className="form-select Account__input"
                                                        aria-label="Default select example"
                                                        name="account_theme"
                                                        value={accountTheme}
                                                        onChange={handleSelectedTheme}
                                                        required
                                                >
                                                        <option value="Default">Default (No Theme)</option>
                                                        <option value="Dark Theme">Dark Theme</option>
                                                        <option value="Purple Theme">Purple Theme</option>
                                                        {/* <option value="Gray Theme">Gray Theme</option>
                                                        <option value="Dark Gray Theme">Dark Gray Theme</option>
                                                        <option value="Purple Theme">Purple Theme</option>
                                                        <option value="Dark Purple Theme">Dark Purple Theme</option> */}
                                                </select>
                                                <div style={{ marginTop: "40px" }}>
                                                        <a
                                                                href="" 
                                                                onClick={handleManageSubscriptionClick}
                                                        >
                                                                Manage your subscription.
                                                        </a>
                                                </div>
                                        </div>
                                ) : (
                                        <>
                                                <p style={{ paddingTop: "50px" }}>Want to support this platform, and <strong>unlock themes to visually enhance your documentation experience</strong>?</p>
                                                <button onClick={handleSubscription} className="Account__subscriber-button">Become a Subscriber!</button>
                                        </>
                                )}

                                <h2 style={{ paddingTop: "40px" }}>Profile Links</h2>

                                <p>Expand your social network by adding links below! You can then quickly choose which of the added links you'd like to show in future documentation you create.</p>

                                <input
                                        type="text"
                                        placeholder="Custom Link Title"
                                        value={customLinkTitle}
                                        onChange={(e) => setCustomLinkTitle(e.target.value)}
                                        className="Account__input-custom-title"
                                        onBlur={() => {
                                                if (customLink.trim().length > 0) {
                                                        saveCustomLink()
                                                }
                                        }}
                                />
                                <input
                                        id="customLinkInput"
                                        type="text"
                                        placeholder="Custom Link Domain"
                                        value={customLink}
                                        onChange={(e) => setCustomLink(e.target.value)}
                                        onBlur={() => {
                                                if (customLinkTitle.trim().length === 0) {
                                                        alert("Please enter a title for your custom domain.")
                                                } else if (customLink.trim()) {
                                                        saveCustomLink()
                                                }
                                        }}
                                        className="Account__edit-input"
                                />

                                <div className="Account__link-divs">
                                        <i class="fa-brands fa-discord Account__icons"></i>
                                        <input
                                                type="text"
                                                value={discordLink}
                                                placeholder="Discord Server Link"
                                                onChange={(e) => setDiscordLink(e.target.value)}
                                                onBlur={() => {
                                                        if (discordLink.trim()) {
                                                                saveCustomLink();
                                                        }
                                                }}
                                                className="Account__edit-input"
                                        />
                                </div>

                                <div className="Account__link-divs">
                                        <i class="fa-brands fa-square-x-twitter Account__icons"></i>
                                        <input
                                                type="text"
                                                value={twitterLink}
                                                placeholder="X Profile Link"
                                                onChange={(e) => setTwitterLink(e.target.value)}
                                                onBlur={() => {
                                                        if (twitterLink.trim()) {
                                                                saveCustomLink();
                                                        }
                                                }}
                                                className="Account__edit-input"
                                        />
                                </div>

                                <div className="Account__link-divs">
                                        <i class="fa-brands fa-linkedin Account__icons"></i>
                                        <input
                                                type="text"
                                                value={linkedInLink}
                                                placeholder="LinkedIn Profile Link"
                                                onChange={(e) => setLinkedInLink(e.target.value)}
                                                onBlur={() => {
                                                        if (linkedInLink.trim()) {
                                                                saveCustomLink();
                                                        }
                                                }}
                                                className="Account__edit-input"
                                        />
                                </div>

                                <div className="Account__link-divs">
                                        <i class="fa-brands fa-youtube Account__icons"></i>
                                        <input
                                                type="text"
                                                value={youtubeLink}
                                                placeholder="YouTube Channel Link"
                                                onChange={(e) => setYoutubeLink(e.target.value)}
                                                onBlur={() => {
                                                        if (youtubeLink.trim()) {
                                                                saveCustomLink();
                                                        }
                                                }}
                                                className="Account__edit-input"
                                        />
                                </div>

                                <div className="Account__link-divs">
                                        <i class="fa-brands fa-square-github Account__icons"></i>
                                        <input
                                                type="text"
                                                value={githubLink}
                                                placeholder="GitHub Profile Link"
                                                onChange={(e) => setGithubLink(e.target.value)}
                                                onBlur={() => {
                                                        if (githubLink.trim()) {
                                                                saveCustomLink();
                                                        }
                                                }}
                                                className="Account__edit-input"
                                        />
                                </div>

                        </div>
                </div>
        )

}

export default Account