// React imports
import { useState } from "react";

// Firebase imports 
import { sendPasswordReset } from "../../firebase";


const Reset = () => {
        const [email, setEmail] = useState()

        const handleReset = async () => {
                if (!email) {
                        alert("Please enter your email.");
                        return;
                }
                await sendPasswordReset(email);
        };

        return (
                <div 
                        className="container-fluid"
                        style={{
                                padding: "0",
                                minHeight: "100vh",
                                backgroundColor: "#6b9ce7"
                        }}
                >

                        <div className="notification"></div>

                        <div className="row" style={{ minHeight: "100vh" }}>

                                <div className="col-12 d-flex justify-content-center align-items-center">

                                        <div className="Auth__box">

                                                <h1 className="Auth__text-center"> Reset Password</h1>

                                                <div className="Auth__input-container">
                                                        <input
                                                                type="email"
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                required
                                                        />
                                                        <label>E-mail Address</label>
                                                </div>

                                                <button
                                                        className="Auth__btn"
                                                        onClick={handleReset}
                                                >
                                                        Reset
                                                </button>

                                        </div>

                                </div>

                        </div>

                </div>
        )
}

export default Reset