import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { useEffect } from "react";
import { auth } from './firebase';
import Main from "./Main"

const syncEmailWithBackend = async () => {
        try {
            const user = auth.currentUser;
            if (user) {
                        const token = await user.getIdToken(); // Get Firebase authentication token
                        const response = await fetch("https://bluebirddocumentationadmin.pythonanywhere.com/users/update/email/", {
                                method: "POST",
                                headers: {
                                        "Content-Type": "application/json",
                                        "auth-token": `Bearer ${token}`,  
                                },
                        });
                        const data = await response.json();
                        console.log("Backend sync response:", data);
                }
        } catch (err) {
                console.error("Error syncing email:", err);
        }
};

const checkSubscriberStatus = async () => {
        try {
                const user = auth.currentUser
                if (user) {
                        const userUid = user.uid
                        const response = await fetch (`https://bluebirddocumentationadmin.pythonanywhere.com/users/${userUid}/`)
                        const data = await response.json()
                        if (!data.is_subscriber) {
                                localStorage.setItem("theme", "Default")
                        }
                }
        } catch (error) {
                console.error("Error in fetching account details: ", error)
        }
}

function App() {
        useEffect(() => {
                const unsubscribe = auth.onAuthStateChanged((user) => {
                    if (user) {
                        syncEmailWithBackend(); // Sync email when user logs in or refreshes
                        checkSubscriberStatus()
                    }
                });
        
                return () => unsubscribe(); // Cleanup listener when component unmounts
            }, []);

        return (
                <BrowserRouter>
                        <Main />
                </BrowserRouter>
        )
}

export default App;