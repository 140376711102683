// React and Router imports
import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";

// Styles
import "../../css/Document.css"
import "../../css/Comments.css"
import "../../css/SupportTicket.css"

function Comments({ type, name, uid, userEmail, userUid }) {

        const [hasComments, setHasComments] = useState(true)
        const [documentComments, setDocumentComments] = useState("")
        const [displayCreateComment, setDisplayCreateComment] = useState(false)
        const [displayReplyTextArea, setDisplayReplyTextArea] = useState(false)
        const [replyStates, setReplyStates] = useState({});
        const [editStates, setEditStates] = useState({})
        const [reply, setReply] = useState("")
        const [edit, setEdit] = useState("")
        const [comment, setComment] = useState("")
        const navigate = useNavigate();

        useEffect(() => {
                getAllCommentsForDocument();
        }, [type, name, uid]);

        const getAllCommentsForDocument = async () => {
                try {
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/documentation/comments/${type}/${name}/${uid}/`, {
                                method: 'GET',
                                headers: { "Content-Type": "application/json" },
                        })

                        if (response.status === 404) { setHasComments(false) }

                        const data = await response.json()
                        const sortedComments = data.comments.sort(
                                (a, b) => new Date(b.created_at) - new Date(a.created_at)
                        );
                        setDocumentComments(sortedComments);
                } catch (error) {
                        console.error("Error in fetching document comments")
                }
        }

        const handleDeleteComment = async (commentId, parentCommentId) => {
                const data = {
                        commentId: commentId,
                        parentComment: parentCommentId
                }

                try {
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/documentation/comments/delete/${type}/${name}/${uid}/`, {
                                method: "DELETE",
                                body: JSON.stringify(data),
                                headers: { 
                                        'Content-Type': 'application/json',
                                        'requesting-user-email': userEmail
                                },
                        })

                        if (response.ok) {
                                console.log("Deleted commented successfully.")
                                navigate(0)
                        } else {
                                console.error("Was not able to delete comment.")
                        }
                } catch (error) {
                        console.error("Was not able to delete comment.")
                }
        }

        const handleSubmitButtonClick = async () => {
                const data = {
                        author: userUid,
                        originalDocumentAuthor: uid,
                        content: comment,
                        isReply: false,
                        documentName: name,
                        documentType: type
                }

                try {
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/documentation/comments/create/`, {
                                method: "POST",
                                body: JSON.stringify(data),
                                headers: { 
                                        'Content-Type': 'application/json',
                                        'requesting-user-email': userEmail
                                },
                        })

                        if (response.ok) {
                                console.log("Successfully created comment.")
                                setDisplayCreateComment(false);
                                setComment(""); 
                                getAllCommentsForDocument();
                                navigate(0)
                        } else {
                                console.error("Error in creating comment.")
                        }
                } catch (error) {
                        console.error("Error in creating comment.")
                }
        }

        const handleEditSubmitButtonClick = async (editCommentId) => {
                const data = {
                        author: userUid,
                        originalDocumentAuthor: uid,
                        content: edit,
                        isReply: false,
                        documentName: name,
                        documentType: type,
                        commentId: editCommentId
                }

                try {
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/documentation/comments/update/`, {
                                method: "PATCH",
                                body: JSON.stringify(data),
                                headers: { 
                                        'Content-Type': 'application/json',
                                        'requesting-user-email': userEmail
                                },
                        })

                        if (response.ok) {
                                console.log("Successfully edited comment.")
                                setDisplayCreateComment(false)
                                setComment("")
                                getAllCommentsForDocument();
                                navigate(0)
                        } else {
                                console.error("Error in editing comment.")
                        }
                } catch (error) {
                        console.error("Error in editing comment.")
                }
        }

        const handleReplySubmitButtonClick = async (parentCommentId) => {
                const data = {
                        author: userUid,
                        originalDocumentAuthor: uid,
                        content: reply,
                        isReply: true,
                        documentName: name,
                        documentType: type,
                        parentComment: parentCommentId
                }

                try {
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/documentation/comments/create/`, {
                                method: "POST",
                                body: JSON.stringify(data),
                                headers: { 
                                        'Content-Type': 'application/json',
                                        'requesting-user-email': userEmail
                                },
                        })

                        if (response.ok) {
                                console.log("Successfully created comment.")
                                setDisplayCreateComment(false);
                                setComment(""); 
                                getAllCommentsForDocument();
                                navigate(0)
                        } else {
                                console.error("Error in creating comment.")
                        }
                } catch (error) {
                        console.error("Error in creating comment.")
                }
        }

        const handlePlusIconClick = () => {
                setDisplayCreateComment((prevState) => !prevState);
        };

        const handleReplyClick = (email, commentId) => {
                setReplyStates((prevState) => ({
                        ...prevState,
                        [commentId]: !prevState[commentId]
                }));
                setReply(`Reply To: ${email} | Comment ID: ${commentId}`);
        };

        const handleEditClick = (commentContent, commentId) => {
                setEditStates((prevState) => ({
                        ...prevState,
                        [commentId]: !prevState[commentId]
                }));
                setEdit(commentContent);
        }

        return (
                <div className="Comment__div">

                        <h5>Comments</h5>

                        <div>
                                {hasComments && documentComments.length > 0 ? (
                                        <>
                                                {documentComments.map((comment) => (
                                                        <div key={comment.id} className={`Comment__comment-area ${comment.is_reply ? 'Comment__reply' : ''}`}>
                                                                {comment.is_reply !== true && (
                                                                        <>
                                                                                <p className="small">{comment.content}</p>
                                                                                <p className="extra-small" style={{ marginBottom: "0px" }}>
                                                                                        <strong>Author:</strong> {comment.author_email || "Anonymous"}
                                                                                </p>
                                                                                <p className="extra-small" style={{ marginBottom: "0px" }}>
                                                                                        <strong>Created At:</strong> {new Date(comment.created_at).toLocaleString()}
                                                                                </p>
                                                                                <p className="extra-small"><strong>Comment ID: </strong> {comment.id}</p>
                                                                                <i class="fa-solid fa-reply Comment__plus-sign" title="Reply" style={{ paddingRight: "10px" }} onClick={() => handleReplyClick(comment.author_email, comment.id)}></i>
                                                                                {userEmail === comment.author_email ? (
                                                                                        <>
                                                                                                <i style={{ paddingRight: "10px" }} class="fa-solid fa-pencil Comment__plus-sign" title="Edit" onClick={() => handleEditClick(comment.content, comment.id)}></i>
                                                                                                <i class="fa-regular fa-square-minus Comment__plus-sign" title="Delete" onClick={() => handleDeleteComment(comment.id, comment.parent_comment)}></i>
                                                                                        </>
                                                                                ) : (
                                                                                        <></>
                                                                                )}
                                                                        </>
                                                                )}
                                                                {comment.is_reply && comment.parent_comment && (
                                                                        <div>
                                                                                <p className="extra-small">{comment.content}</p>
                                                                                <p className="extra-small" style={{ marginBottom: "0px" }}><strong>Author:</strong> {comment.author_email}</p>
                                                                                <p className="extra-small" style={{ marginBottom: "0px" }}><strong>Created At:</strong> {new Date(comment.created_at).toLocaleString()}</p>
                                                                                <p className="extra-small"><strong>Comment ID: </strong> {comment.id}</p>
                                                                                <i class="fa-solid fa-reply Comment__plus-sign" title="Reply" style={{ paddingRight: "10px" }} onClick={() => handleReplyClick(comment.author_email, comment.id)}></i>
                                                                                {userEmail === comment.author_email ? (
                                                                                        <>
                                                                                                <i style={{ paddingRight: "10px" }} class="fa-solid fa-pencil Comment__plus-sign" title="Edit" onClick={() => handleEditClick(comment.content, comment.id)}></i>
                                                                                                <i class="fa-regular fa-square-minus Comment__plus-sign" title="Delete" onClick={() => handleDeleteComment(comment.id, comment.parent_comment)}></i>
                                                                                        </>
                                                                                ) : (
                                                                                        <></>
                                                                                )}
                                                                        </div>
                                                                )}
                                                                {replyStates[comment.id] ? (
                                                                        <>
                                                                                <textarea
                                                                                        value={reply}
                                                                                        className="Comment__comment-text-area"
                                                                                        onChange={(e) => setReply(e.target.value)}
                                                                                        rows={7}
                                                                                        required
                                                                                ></textarea>
                                                                                <button onClick={() => handleReplySubmitButtonClick(comment.id)} className="Comment__submit-button">Submit</button>
                                                                        </>
                                                                ) : editStates[comment.id] ? (
                                                                        <>
                                                                                <textarea
                                                                                        value={edit}
                                                                                        className="Comment__comment-text-area"
                                                                                        onChange={(e) => setEdit(e.target.value)}
                                                                                        rows={7}
                                                                                        required
                                                                                ></textarea>
                                                                                <button onClick={() => handleEditSubmitButtonClick(comment.id)} className="Comment__submit-button">Submit</button>
                                                                        </>
                                                                ) : (
                                                                        <></>
                                                                )}
                                                        </div>
                                                ))}
                                                <i className="fa-solid fa-plus Comment__plus-sign" onClick={handlePlusIconClick}></i>
                                                {displayCreateComment ? (
                                                        <>
                                                                <textarea
                                                                        value={comment}
                                                                        className="Comment__comment-text-area"
                                                                        onChange={(e) => setComment(e.target.value)}
                                                                        rows={7}
                                                                        required
                                                                ></textarea>
                                                                <button onClick={handleSubmitButtonClick} className="Comment__submit-button">Submit</button>
                                                        </>
                                                ) : (
                                                        <></>
                                                )}
                                        </>
                                ) : (
                                        <div className="Comment__comment-area">
                                                <p className="extra-small">There are no comments for this document yet.</p>
                                                <i className="fa-solid fa-plus Comment__plus-sign" onClick={handlePlusIconClick}></i>
                                                {displayCreateComment ? (
                                                        <>
                                                                <textarea
                                                                        value={comment}
                                                                        className="Comment__comment-text-area"
                                                                        onChange={(e) => setComment(e.target.value)}
                                                                        rows={7}
                                                                        required
                                                                ></textarea>
                                                                <button onClick={handleSubmitButtonClick} className="Comment__submit-button">Submit</button>
                                                        </>
                                                ) : (
                                                        <></>
                                                )}
                                        </div>
                                )}
                        </div>

                </div>
        )

}

export default Comments