// React and Router
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"

// Firebase
import { auth } from "../../firebase.js"

// Third-party Components
import Select from 'react-select';
import ReactQuill from 'react-quill';
import Quill from "quill";
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
import 'quill-table-ui/dist/index.css';
import Tesseract from 'tesseract.js';
import * as pdfjsLib from 'pdfjs-dist';

// Component Imports
import WalkthroughSidebar from "./WalkthroughSidebar.js";

// Styles
import "../../css/Create.css"
import '../../css/Account.css'
import "../../css/DocumentationMainPage.css"
import "../../css/CreateWalkthrough.css"

pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';
const FontAttributor = Quill.import("attributors/class/font");
FontAttributor.whitelist = ["sofia", "arial", "times-new-roman", "monospace"]; // Add more fonts as needed
Quill.register(FontAttributor, true);
const Inline = Quill.import('blots/inline');
class InlineCode extends Inline {
        static create() {
                const node = super.create();
                node.setAttribute('class', 'inline-code');
                return node;
        }
        static formats(node) {
                return true;
        }
}
InlineCode.blotName = 'inline-code';
InlineCode.tagName = 'code';
Quill.register(InlineCode);

function CreateWalkthrough() {

        const [authorUid, setAuthorUid] = useState("")
        const [authorEmail, setAuthorEmail] = useState("")
        const [authorName, setAuthorName] = useState("")
        const [documentType, setDocumentType] = useState("")
        const [documentOrder, setDocumentOrder] = useState([])
        const [selectedDocuments, setSelectedDocuments] = useState([]);
        const [availableDocuments, setAvailableDocuments] = useState([]);
        const [tutorialName, setTutorialName] = useState("")
        const [description, setDescription] = useState("")
        const [lastUpdatedBy, setLastUpdatedBy] = useState("")
        const [tutorialCover, setTutorialCover] = useState("")
        const [isPublic, setIsPublic] = useState(false)
        const [completed, setCompleted] = useState(false)

        // Permission API Data
        const [currentEmail, setCurrentEmail] = useState("")
        const [emails, setEmails] = useState([]); 

        // Social Media Links
        const [customLink, setCustomLink] = useState("")
        const [customLinkTitle, setCustomLinkTitle] = useState("")
        const [discordLink, setDiscordLink] = useState("")
        const [linkedInLink, setLinkedInLink] = useState("")
        const [youtubeLink, setYoutubeLink] = useState("")
        const [githubLink, setGithubLink] = useState("")
        const [twitterLink, setTwitterLink] = useState("")

        const navigate = useNavigate()
        const walkthroughNameMaxLength = 150
        const tutorialCoverMaxLength = 10000;

        useEffect(() => {
                const unsubscribe = auth.onAuthStateChanged((user) => {
                        if (user) {
                                setAuthorUid(user.uid)
                                setAuthorEmail(user.email)
                        } else {
                                setAuthorUid(null)
                                setAuthorEmail(null)
                        }
                })
                return () => { unsubscribe() }
        }, [])

        useEffect(() => { 
                if (authorUid) {
                        fetchAccountDetails()
                        fetchDocumentDetails()
                }
        }, [authorUid])

        useEffect(() => {
                const theme = localStorage.getItem('theme') || 'Default';
                const root = document.documentElement;
        
                if (theme === 'Dark Theme') {
                        root.style.setProperty('--main-content-color', '#5B92E5')
                        root.style.setProperty('--email-item-color', '#5B92E5')
                        root.style.setProperty('--bg-color', '#121d2d')
                        root.style.setProperty('--submit-button-color', '#5B92E5')
                        root.style.setProperty('--bg-slider-box', "#5B92E5")
                        root.style.setProperty('--bg-slider', '#3f66a0')
                        root.style.setProperty('--email-item-bg', '#000000')
                        root.style.setProperty('--bg-submit-button', '#000000')
                        root.style.setProperty("--placeholder-color", "#6b9ce7")
                        root.style.setProperty('--main-content-bg-color', '#121d2d')
                        root.style.setProperty('--btn-color', '#5B92E5')
                        root.style.setProperty('--bg-button', '#000000')
                        root.style.setProperty('--btn-font-color', '#5B92E5')
                        root.style.setProperty('--quill-container-border-color', '#000000')
                        root.style.setProperty('--quill-toolbar-border-color', '#000000')
                        root.style.setProperty('--quill-toolbar-bg-color', '#000000')
                        root.style.setProperty('--quill-container-bg-color', '#1b2b44')
                } else if (theme === "Purple Theme") {
                        root.style.setProperty('--main-content-color', '#190019')
                        root.style.setProperty('--email-item-color', '#f2e5f2')
                        root.style.setProperty('--bg-color', '#f2e5f2')
                        root.style.setProperty('--submit-button-color', '#f2e5f2')
                        root.style.setProperty('--bg-slider-box', "#bf7fbf")
                        root.style.setProperty('--bg-slider', '#190019')
                        root.style.setProperty('--email-item-bg', '#190019')
                        root.style.setProperty('--bg-submit-button', '#190019')
                        root.style.setProperty("--placeholder-color", "#993299")
                        root.style.setProperty('--main-content-bg-color', '#f2e5f2')
                        root.style.setProperty('--bg-button', '#190019')
                        root.style.setProperty('--btn-font-color', '#f2e5f2')
                        root.style.setProperty('--quill-container-border-color', '#190019')
                        root.style.setProperty('--quill-toolbar-border-color', '#190019')
                        root.style.setProperty('--quill-toolbar-bg-color', '#190019')
                }
        }, []);

        const fetchDocumentDetails = async () => {
                try {
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/page-details/author/${authorUid}/`)
                        const data = await response.json()
                        if (response.ok) {
                                const processedDocuments = data.map((doc) => ({
                                        id: doc.id,
                                        name: doc.space || doc.page_name,
                                        type: doc.space ? 'Space' : 'Page',
                                }));
                                setAvailableDocuments(processedDocuments);
                        }
                } catch (error) {
                        console.error("Error in fetching document details: ", error)
                }
        }

        const fetchAccountDetails = async () => {
                try {
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/users/${authorUid}/`)
                        const data = await response.json()

                        if (response.ok) {
                                setYoutubeLink(data.youtube_channel)
                                setCustomLink(data.custom_domain)
                                setCustomLinkTitle(data.custom_domain_title)
                                setDiscordLink(data.discord_server)
                                setLinkedInLink(data.linkedin_link)
                                setGithubLink(data.github_link)
                                setTwitterLink(data.twitter_link)
                                setAuthorName(data.name)
                        }

                } catch (error) {
                        console.error("Error in fetching account details: ", error)
                }
        }

        const createPermissionTableEntry = async () => {

                const data = {
                        name: tutorialName,
                        documentationType: "Tutorial",
                        userEmail: emails,
                        isPublic: isPublic,
                        documentUid: authorUid,
                        documentUrl: `walkthrough/${tutorialName}/${authorUid}/`,
                        description: description
                }

                try {
                        const response = await fetch("https://bluebirddocumentationadmin.pythonanywhere.com/permissions/create/", {
                                method: "POST",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify(data),
                        })

                        if (response.ok) {
                                console.log("Successfully set permissions.")
                        } else {
                                console.log("Failed to create permissions.")
                        }
                } catch (error) {
                        console.error("Error submitting the form:", error);
                }
        }

        const handleSubmit = async () => {
                // Name validation checks
                if (!tutorialName || tutorialName.trim() === "") {
                        alert("Please enter a name for your walkthrough.");
                        return;
                }

                // Check that name only contains letters and numbers
                if (!/^[a-zA-Z0-9\s]+$/.test(tutorialName)) {
                        alert("Walkthrough name must contain only letters and numbers.");
                        return;
                }

                const textOnly = tutorialCover.replace(/<[^>]*>/g, '');
                if (textOnly.length > tutorialCoverMaxLength) {
                        alert(`Please keep the character count below ${tutorialCoverMaxLength}. Current count: ${textOnly.length}`);
                        return;
                }

                if (emails.length > 0) {
                        createPermissionTableEntry()
                }

                const data = {
                        authorUid: authorUid,
                        authorEmail: authorEmail,
                        authorName: authorName,
                        documentOrder: documentOrder,
                        tutorialName: tutorialName,
                        description: description,
                        tutorialCover: tutorialCover,
                        isPublic: isPublic,
                        completed: completed,
                        customLink: customLink,
                        customLinkTitle: customLinkTitle,
                        twitterLink: twitterLink,
                        discordLink: discordLink,
                        linkedInLink: linkedInLink,
                        githubLink: githubLink,
                        youtubeLink: youtubeLink,
                }

                try {
                        const user = auth.currentUser;
                        const token = user ? await user.getIdToken() : null;
                
                        if (!token) {
                                console.error("User is not authenticated.");
                                return;
                        }

                        const response = await fetch("https://bluebirddocumentationadmin.pythonanywhere.com/walkthrough/create/", {
                                method: "POST",
                                headers: {
                                        'Content-Type': 'application/json',
                                        'requesting-user-uid': authorUid,
                                        'requesting-user-email': authorEmail,
                                        'auth-token': `Bearer ${token}`,
                                },
                                body: JSON.stringify(data),
                        });

                        if (response.ok) {
                                console.log("Successfully submitted the form!")
                        } else {
                                console.log("Failed to submit the form.")
                        }
                } catch (error) {
                        console.error("Error submitting the form: ", error)
                }

                navigate(`/walkthroughs/${tutorialName}/${authorUid}/`)
        }
        
        const modules = {
                toolbar: [
                        [{ 'header': '1' }, { 'header': '2' }],   
                        [{ 'size': ['small', 'normal', 'large'] }],    
                        ['bold', 'italic', 'underline', 'strike'],   
                        [{ 'color': [] }, { 'background': [] }],    
                        [{ 'script': 'sub' }, { 'script': 'super' }],     
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],   
                        [{ 'indent': '-1' }, { 'indent': '+1' }],     
                        [{ 'align': [] }],   
                        ['blockquote', 'code-block'],   
                        ['link', 'video'],
                        ['inline-code'],
                        ['formula'], 
                        ['clean']  
                ],
                imageResize: {
                        parchment: Quill.import('parchment'),
                        modules: ['Resize', 'DisplaySize']
                }
        };

        const handleAddEmail = () => {
                if (currentEmail && !emails.some(item => item.email === currentEmail)) {
                        setEmails([...emails, { email: currentEmail, permission: "Can Edit" }]);
                        setCurrentEmail("");
                } else {
                        alert("This email has already been added.");
                }
        };

        const handleRemoveEmail = (index) => {
                const updatedEmails = [...emails];
                updatedEmails.splice(index, 1);
                setEmails(updatedEmails);
        };            

        const handlePermissionChange = (index, newPermission) => {
                const updatedEmails = [...emails];
                updatedEmails[index].permission = newPermission;
                setEmails(updatedEmails); 
        };

        const handleAddDocument = (document) => {
                if (!selectedDocuments.some((doc) => doc.id === document.id)) {
                        setSelectedDocuments([...selectedDocuments, document]);
                        setDocumentOrder([...documentOrder, document.id]);
                } else {
                        alert("Document already added.");
                }
        };
            
        const handleRemoveDocument = (index) => {
                const updatedDocuments = [...selectedDocuments];
                updatedDocuments.splice(index, 1);
                setSelectedDocuments(updatedDocuments);
            
                const updatedOrder = [...documentOrder];
                updatedOrder.splice(index, 1);
                setDocumentOrder(updatedOrder);
        };
            
        const handleReorderDocuments = (startIndex, endIndex) => {
                const result = Array.from(selectedDocuments);
                const [removed] = result.splice(startIndex, 1);
                result.splice(endIndex, 0, removed);
                setSelectedDocuments(result);
            
                const orderResult = Array.from(documentOrder);
                const [removedOrder] = orderResult.splice(startIndex, 1);
                orderResult.splice(endIndex, 0, removedOrder);
                setDocumentOrder(orderResult);
        };

        const handleToggle = (e) => { setIsPublic(e.target.checked) }
        const handleCompletedToggle = (e) => { setCompleted(e.target.checked) }
        const handleHomeButtonClick = () => { navigate("/") }
        const handleLoginButtonClick = () => { navigate("/login") }
        const handleRegisterButtonClick = () => { navigate("/register") }

        if (!authorUid) {
                return (
                        <div className="DocumentationMainPage__div">
                                {/* <Sidebar /> */}
                                <div className="DocumentationMainPage__main-content">
                                        <p className="large">It looks like you're not signed in.</p>
                                        <p className="medium">
                                                <strong>To create a walkthrough, you must have an account with us</strong>. If you already have an account, you can login by clicking the Login button 
                                                below. If you don't already have an account, you can create a new one by clicking the Register button below.
                                        </p>
                                        <button onClick={handleHomeButtonClick} style={{ marginRight: "10px" }} className="ConflictResolver__button">Home</button>
                                        <button onClick={handleLoginButtonClick} style={{ marginRight: "10px" }} className="ConflictResolver__button">Login</button>
                                        <button onClick={handleRegisterButtonClick} className="ConflictResolver__button">Register</button>
                                </div>
                        </div>
                )
        }

        return (
                <div className="Create__div">

                        <WalkthroughSidebar />

                        <div  className="Create__main-content">

                                <h2>Create a Walkthrough</h2>

                                <div className="Create__form-group">
                                        <label>Name: </label>
                                        <input
                                                type="text"
                                                className="Create__input-short-field"
                                                value={tutorialName}
                                                onChange={(e) => setTutorialName(e.target.value)}
                                                maxLength={walkthroughNameMaxLength}
                                                placeholder={`Max ${walkthroughNameMaxLength} characters`}
                                                required
                                        />
                                </div>

                                <div className="Create__form-group">
                                        <label>Short Description: </label>
                                        <input
                                                type="text"
                                                className="Create__input-full-field"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                        />
                                </div>
                                <div className="Create__form-group-helper-text">
                                        <i className="fas fa-info-circle Create__info-icon" title="Information"></i>
                                        <p className="Create__helper-text">
                                                This description is what invited users will see about 
                                                this walkthrough.
                                        </p>
                                </div>

                                <div className="Create__form-group">
                                        <label>Make this content public?</label>
                                        <label className="Create__switch">
                                                <input
                                                        type="checkbox"
                                                        checked={isPublic}
                                                        onChange={handleToggle}
                                                />
                                                <span className="Create__slider round"></span>
                                        </label>
                                </div>
                                <div className="Create__form-group-helper-text">
                                        <i className="fas fa-info-circle Create__info-icon" title="Information"></i>
                                        <p className="Create__helper-text">
                                                Public content is viewable by anyone that has this walkthrough's URL. 
                                        </p>
                                </div>

                                <div className="Create__form-group">
                                        <label>Publish this walkthrough upon submitting?</label>
                                        <label className="Create__switch">
                                                <input
                                                        type="checkbox"
                                                        checked={completed}
                                                        onChange={handleCompletedToggle}
                                                />
                                                <span className="Create__slider round"></span>
                                        </label>
                                </div>
                                <div className="Create__form-group-helper-text">
                                        <i className="fas fa-info-circle Create__info-icon" title="Information"></i>
                                        <p className="Create__helper-text">
                                                Whether the walkthrough is public or not, this walkthrough will not be <strong>viewable</strong> by anyone; including 
                                                added collaborators that have "Can View" permission. Collaborators that have "Can Edit" permission can edit this walkthrough.
                                        </p>
                                </div>

                                <div className="Create__form-group" style={{ flexDirection: "column", marginBottom: "15px" }}>
                                        <label>Invite others to collaborate on this content.</label>
                                        {emails.map((item, index) => (
                                                <div key={index} className="Create__email-item" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                                        <span>{item.email}</span>
                                                        <select
                                                                value={item.permission}
                                                                className="Create__select"
                                                                onChange={(e) => handlePermissionChange(index, e.target.value)}
                                                                style={{ marginLeft: '10px', padding: '5px' }}
                                                        >
                                                                {/* <option value="Can Edit">Can Edit</option> */}
                                                                <option value="Can View">Can View</option>
                                                        </select>
                                                        <button onClick={() => handleRemoveEmail(index)} style={{ marginLeft: '10px', background: 'none', border: 'none', cursor: 'pointer' }}>
                                                                <i className="fas fa-trash-alt" title="Remove Email" style={{ color: "white" }}></i>
                                                        </button>
                                                </div>
                                        ))}     
                                        <div className="Create__email-input-container">
                                                <input
                                                        type="email"
                                                        className="Create__email-input"
                                                        placeholder="Enter E-mail Address"
                                                        value={currentEmail}
                                                        onChange={(e) => setCurrentEmail(e.target.value)}
                                                        onKeyDown={(e) => e.key === "Enter" && handleAddEmail()}
                                                />
                                                <button onClick={handleAddEmail} className="Create__add-email-btn">
                                                        <i className="fas fa-plus"></i>
                                                </button>
                                        </div>
                                </div>
                                <div className="Create__form-group-helper-text">
                                        <i className="fas fa-info-circle Create__info-icon" title="Information"></i>
                                        <p className="Create__helper-text">
                                                <strong>Please note</strong>, as of this current release, <strong>any documents you attach to this walkthrough will be visible to whomever you share this 
                                                walkthrough with</strong>; regardless of the individual document permissions. Please be mindful of this before sharing any documentation 
                                                that might be sensitive.
                                        </p>
                                </div>
                                <div className="Create__form-group-helper-text" style={{ marginBottom: "60px" }}>
                                        <i className="fas fa-info-circle Create__info-icon" title="Information"></i>
                                        <p className="Create__helper-text">
                                                As of the current release, you cannot add editors to walkthroughs. This will be added once we add other permission roles 
                                                like admin and such.
                                        </p>
                                </div>

                                <input
                                        type="text"
                                        placeholder="Custom Link Title"
                                        value={customLinkTitle}
                                        onChange={(e) => setCustomLinkTitle(e.target.value)}
                                        className="Account__input-custom-title"
                                />
                                <input
                                        id="customLinkInput"
                                        type="text"
                                        placeholder="Custom Link Domain"
                                        value={customLink}
                                        onChange={(e) => setCustomLink(e.target.value)}
                                        className="Account__edit-input"
                                />
                                <div className="Account__link-divs">
                                        <i class="fa-brands fa-discord Account__icons"></i>
                                        <input
                                                type="text"
                                                value={discordLink}
                                                placeholder="Discord Server Link"
                                                onChange={(e) => setDiscordLink(e.target.value)}
                                                className="Account__edit-input"
                                        />
                                </div>
                                <div className="Account__link-divs">
                                        <i class="fa-brands fa-square-x-twitter Account__icons"></i>
                                        <input
                                                type="text"
                                                value={twitterLink}
                                                placeholder="X Profile Link"
                                                onChange={(e) => setTwitterLink(e.target.value)}
                                                className="Account__edit-input"
                                        />
                                </div>
                                <div className="Account__link-divs">
                                        <i class="fa-brands fa-linkedin Account__icons"></i>
                                        <input
                                                type="text"
                                                value={linkedInLink}
                                                placeholder="LinkedIn Profile Link"
                                                onChange={(e) => setLinkedInLink(e.target.value)}
                                                className="Account__edit-input"
                                        />
                                </div>
                                <div className="Account__link-divs">
                                        <i class="fa-brands fa-youtube Account__icons"></i>
                                        <input
                                                type="text"
                                                value={youtubeLink}
                                                placeholder="YouTube Channel Link"
                                                onChange={(e) => setYoutubeLink(e.target.value)}
                                                className="Account__edit-input"
                                        />
                                </div>
                                <div className="Account__link-divs">
                                        <i class="fa-brands fa-square-github Account__icons"></i>
                                        <input
                                                type="text"
                                                value={githubLink}
                                                placeholder="GitHub Profile Link"
                                                onChange={(e) => setGithubLink(e.target.value)}
                                                className="Account__edit-input"
                                        />
                                </div>
                                <div className="Create__form-group-helper-text" style={{ marginBottom: "60px" }}>
                                        <i className="fas fa-info-circle Create__info-icon" title="Information"></i>
                                        <p className="Create__helper-text">
                                                These are links that users will see when visiting this document. The links are automatically pulled from what's saved in your account settings. Feel free to edit any of the links if you don't want to use what's pulled from 
                                                your account settings. <strong>If you don't want your saved link(s) to show for this particular document, </strong>just remove the contents of that input; until you see the placeholder text again.
                                        </p>
                                </div>

                                <div className="Create__form-group CreateWalkthrough__document-order-div">
                                        <label>Select and Order Documents (Spaces/Pages):</label>
                                        <div className="Create__form-group-helper-text">
                                                <i className="fas fa-info-circle Create__info-icon" title="Information"></i>
                                                <p className="Create__helper-text">
                                                        To make your walkthrough, select which documents you'd like to include in the walkthrough (by searching for them or clicking on them). Then you can also adjust the order in which documents appear 
                                                        in the walkthrough as well below.
                                                </p>
                                        </div>

                                        <Select
                                                options={availableDocuments.map(doc => ({
                                                        value: doc,
                                                        label: `${doc.type}: ${doc.name}`
                                                }))}
                                                onChange={(selected) => {
                                                        if (selected) {
                                                                handleAddDocument(selected.value)
                                                        }
                                                }}
                                                placeholder="Search for documents..."
                                                isClearable
                                                className="CreateWalkthrough__document-search"
                                        />

                                        <div className="CreateWalkthrough__available-documents">
                                                {availableDocuments.map((doc) => (
                                                <button
                                                        key={doc.id}
                                                        onClick={() => handleAddDocument(doc)}
                                                        className="CreateWalkthrough__add-document-button"
                                                >
                                                        <strong>{doc.type}:</strong> {doc.name}
                                                </button>
                                                ))}
                                        </div>

                                        <div className="CreateWalkthrough__selected-documents">
                                                {selectedDocuments.map((doc, index) => (
                                                <div key={doc.id} className="CreateWalkthrough__selected-document-item">
                                                        <span><strong>{doc.type}:</strong> {doc.name}</span>
                                                        <button
                                                                onClick={() => handleRemoveDocument(index)}
                                                                className="CreateWalkthrough__remove-document-button"
                                                        >
                                                        Remove
                                                        </button>
                                                        {index > 0 && (
                                                        <button
                                                                onClick={() => handleReorderDocuments(index, index - 1)}
                                                                className="CreateWalkthrough__move-up-button"
                                                        >
                                                                Move Up
                                                        </button>
                                                        )}
                                                        {index < selectedDocuments.length - 1 && (
                                                        <button
                                                                onClick={() => handleReorderDocuments(index, index + 1)}
                                                                className="CreateWalkthrough__move-down-button"
                                                        >
                                                                Move Down
                                                        </button>
                                                        )}
                                                </div>
                                                ))}
                                        </div>
                                </div>

                                <div className="Create__editor-div">
                                        <ReactQuill
                                                value={tutorialCover}
                                                onChange={(content) => {
                                                        const textOnly = content.replace(/<[^>]*>/g, '');
                                                        if (textOnly.length <= tutorialCoverMaxLength) {
                                                                setTutorialCover(content);
                                                        } else {
                                                                alert(`Please keep the character count below ${tutorialCoverMaxLength}. Current count: ${textOnly.length}`);
                                                        }
                                                }}
                                                modules={modules}
                                                formats={[
                                                        'header', 'list', 'bold', 'italic', 'underline', 'code-block', 'blockquote',
                                                        'link', 'image', 'video', 'strike', 'color', 'background', 'indent',
                                                        'size', 'align', 'script', 'direction', 'code', 'inline-code', 'formula'
                                                ]}
                                                style={{ height: "70vh" }}
                                        />
                                        {/* <div className="Create__output" dangerouslySetInnerHTML={{ __html: content }}></div> */}
                                </div>
                                <div className="Create__form-group-helper-text CreateWalkthrough__tutorial-cover-helper-text">
                                        <i className="fas fa-info-circle Create__info-icon" title="Information"></i>
                                        <p className="Create__helper-text">
                                                Characters: {tutorialCover.replace(/<[^>]*>/g, '').length} / {tutorialCoverMaxLength}
                                        </p>
                                </div>

                                <button onClick={handleSubmit} className="CreateWalkthrough__submit-button">Submit</button>

                        </div>

                </div>
        )

}

export default CreateWalkthrough