// React and Router imports
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";

// Firebase imports
import { auth } from "../../firebase.js"

// Component imports 
import WalkthroughSidebar from "../walkthroughs/WalkthroughSidebar";

// PDF generation imports
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

// CSS imports
import "../../css/DocumentationMainPage.css"
import "../../css/Document.css"
import "../../css/DocumentationHomeComponent.css"
import "../../css/Create.css"
import "../../css/ConflictResolver.css"
import "../../css/WalkthroughPage.css"

function WalkthroughPage() {

        const { tutorialName, originalAuthorUid } = useParams()
        const [userUid, setUserUid] = useState("")
        const [userEmail, setUserEmail] = useState("")
        const [userName, setUserName] = useState("")
        const [loading, setLoading] = useState(true)
        const [hasPermission, setHasPermission] = useState(true)
        const [tutorialResponseData, setTutorialResponseData] = useState("")
        const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
        const [selectedDocuments, setSelectedDocuments] = useState([]);
        const [canEdit, setCanEdit] = useState(true)
        const [published, setPublished] = useState(true)
        const [isShareVisible, setIsShareVisible] = useState(false);
        const [isHighlightManagerVisible, setIsHighlightManagerVisible] = useState(false);
        const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0);
        const [authResolved, setAuthResolved] = useState(false);
        const [highlights, setHighlights] = useState({});
        const pdfRef = useRef()
        const navigate = useNavigate()

        const walkthroughRef = useRef(null);

        // Social Media Links
        const [customLink, setCustomLink] = useState("")
        const [customLinkTitle, setCustomLinkTitle] = useState("")
        const [discordLink, setDiscordLink] = useState("")
        const [linkedInLink, setLinkedInLink] = useState("")
        const [youtubeLink, setYoutubeLink] = useState("")
        const [githubLink, setGithubLink] = useState("")
        const [twitterLink, setTwitterLink] = useState("")

        // Share Link
        const link = `https://bluebird-documentation.com/walkthroughs/${tutorialName}/${originalAuthorUid}`;

        useEffect(() => {
                const unsubscribe = auth.onAuthStateChanged((user) => {
                    if (user) {
                                setUserUid(user.uid);
                                setUserEmail(user.email); 
                        } else {
                                setUserUid(null);
                                setUserEmail(null); 
                                setLoading(false)
                        }
                        setAuthResolved(true);
                });
                return () => { unsubscribe(); };
        }, []);

        useEffect(() => {
                const theme = localStorage.getItem('theme') || 'Default';
                const root = document.documentElement;
        
                if (theme === 'Dark Theme') {
                        root.style.setProperty('--blockquote-border-color', '#000000')
                        root.style.setProperty('--blockquote-bg-color', '#090e16')
                        root.style.setProperty('--bg-color', '#121d2d')
                        root.style.setProperty('--font-color', '#5B92E5')
                        root.style.setProperty('--font-color-icons', '#5B92E5')
                        root.style.setProperty('--btn-color', '#5B92E5')
                        root.style.setProperty('--bg-button', '#000000')
                }

                if (theme === "Purple Theme") {
                        root.style.setProperty('--blockquote-border-color', '#190019')
                        root.style.setProperty('--blockquote-bg-color', '#e5cce5')
                        root.style.setProperty('--bg-color', '#f2e5f2')
                        root.style.setProperty('--font-color', '#190019')
                        root.style.setProperty('--font-color-icons', '#f2e5f2')
                        root.style.setProperty('--h2-color', '#f2e5f2')
                        root.style.setProperty('--btn-color', '#f2e5f2')
                        root.style.setProperty('--bg-floating-div', '#190019')
                        root.style.setProperty('--bg-button', '#190019')
                }
        }, []);

        useEffect(() => {
                const loadDocuments = async () => {
                        const documents = await fetchDocumentsForWalkthrough(tutorialResponseData.document_order);
                        if (documents) {
                                setSelectedDocuments(documents);
                        }
                };
            
                loadDocuments();
        }, [tutorialResponseData.document_order]);

        useEffect(() => {
                if (originalAuthorUid && authResolved) { 
                        fetchTutorialContent();
                        fetchAccountDetails()
                }
        }, [tutorialName, originalAuthorUid, userUid, userEmail]);

            useEffect(() => {
                const loadHighlights = async () => {
                    if (!userUid || !authResolved) return;
                
                    // Get highlights from backend
                    const backendHighlights = await fetchHighlightsFromBackend();
                
                    if (backendHighlights && backendHighlights.length > 0) {
                        setHighlights(prev => ({...prev, [currentDocumentIndex]: backendHighlights}));
                    
                        // Apply highlights to DOM after a short delay to ensure content is loaded
                        setTimeout(() => {
                            backendHighlights.forEach(highlight => {
                                applyHighlightToDOM(highlight);
                            });
                        }, 300);
                    }
                };
            
                if (tutorialName && originalAuthorUid && authResolved) {
                    loadHighlights();
                }
            }, [currentDocumentIndex, tutorialName, originalAuthorUid, userUid, authResolved]);

            // Helper function to apply highlight to DOM
            const applyHighlightToDOM = (highlight) => {
                const content = document.querySelector('.ql-editor');
                if (!content) return;
            
                const walker = document.createTreeWalker(content, NodeFilter.SHOW_TEXT);
                let node;
                while (node = walker.nextNode()) {
                    if (node.textContent.includes(highlight.text)) {
                        try {
                            const range = document.createRange();
                            range.setStart(node, highlight.position || 0);
                            range.setEnd(node, (highlight.position || 0) + highlight.text.length);
                            const span = document.createElement('span');
                            span.className = 'highlighted-text';
                            span.dataset.highlightId = highlight.id;
                            if (highlight.color) {
                                span.style.backgroundColor = highlight.color;
                            }
                            range.surroundContents(span);
                        
                            // Add click handler for highlight removal
                            span.addEventListener('contextmenu', (e) => {
                                e.preventDefault();
                                if (window.confirm('Remove this highlight?')) {
                                        removeHighlight(highlight.id);
                                }
                            });
                        
                            // Break after finding first instance
                            break;
                        } catch (error) {
                            console.error("Error applying highlight:", error);
                        }
                    }
                }
            };

            // Modify the handleTextHighlight function
            const handleTextHighlight = () => {
                const selection = window.getSelection();
            
                if (!selection.isCollapsed) {
                    const range = selection.getRangeAt(0);
                    const highlightId = Date.now();
                    const highlightText = selection.toString();
                    const position = range.startOffset;
                    const span = document.createElement('span');
                    span.className = 'highlighted-text';
                    span.dataset.highlightId = highlightId;
                
                    try {
                        range.surroundContents(span);
                    
                        const newHighlight = {
                            id: highlightId,
                            text: highlightText,
                            position: position,
                            color: "#ffff00" // Default yellow
                        };
                    
                        // Add to state
                        setHighlights(prev => ({
                            ...prev,
                            [currentDocumentIndex]: [
                                ...(prev[currentDocumentIndex] || []),
                                newHighlight
                            ]
                        }));
                    
                        // Save to backend
                        saveHighlightToBackend(newHighlight);
                    
                        // Add remove functionality via context menu
                        span.addEventListener('contextmenu', (e) => {
                            e.preventDefault();
                            if (window.confirm('Remove this highlight?')) {
                                removeHighlight(highlightId);
                            }
                        });
                    } catch (error) {
                        console.log("Could not highlight this selection");
                    }
                
                    // Clear selection after highlighting
                    selection.removeAllRanges();
                }
            };
            
            // Add function to remove highlights
            const removeHighlight = async (highlightId) => {
                // Remove from DOM
                const highlightElement = document.querySelector(`[data-highlight-id="${highlightId}"]`);
                if (highlightElement) {
                    // Replace the span with its text content
                    const textNode = document.createTextNode(highlightElement.textContent);
                    highlightElement.parentNode.replaceChild(textNode, highlightElement);
                }
            
                // Remove from state
                setHighlights(prev => {
                    const currentDocHighlights = prev[currentDocumentIndex] || [];
                    return {
                        ...prev,
                        [currentDocumentIndex]: currentDocHighlights.filter(h => h.id !== highlightId)
                    };
                });
            
                // Remove from backend
                await deleteHighlightFromBackend(highlightId);
            };      
                  
        const fetchAccountDetails = async () => {
                try {
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/users/${originalAuthorUid}/`)
                        const data = await response.json()
                        if (response.ok) {
                                setUserName(data.name)
                        }
                } catch (error) {
                        console.error("Error in fetching account details: ", error)
                }
        }

        const fetchTutorialContent = async () => {
                setHasPermission(true)
                setPublished(true)
                try {
                        const user = auth.currentUser;
                        const token = user ? await user.getIdToken() : null;
                        if (!token) {
                                console.error("User is not authenticated.");
                        }
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/walkthrough/${tutorialName}/${originalAuthorUid}/`, {
                                method: 'GET',
                                headers: {
                                        ...(userUid && { 'requesting-user-uid': userUid }),
                                        ...(userEmail && { 'requesting-user-email': userEmail }),
                                        ...(token && { 'auth-token': `Bearer ${token}` }),
                                }
                        })
                        const data = await response.json()
                        if (data.is_public) {
                                setHasPermission(true)
                        } else if (response.status === 403) {
                                setHasPermission(false)
                                setLoading(false)
                                return
                        }
                        if (response.status === 404) {
                                setPublished(false)
                                setLoading(false)
                                return
                        }
                        let canEditValue = false
                        if (!userUid) {
                                canEditValue = false
                        } else if (userUid === originalAuthorUid) {
                                canEditValue = true
                        } else if (data.permissions) {
                                canEditValue = data.permissions.can_edit
                                setCanEdit(data.permissions.can_edit)
                        }
                        setCanEdit(canEditValue)
                        if (!data.completed && !canEditValue) {
                                setPublished(false)
                                setLoading(false)
                                return
                        }
                        const updatedTutorialCover = transformContent(data.tutorial_cover);
                        setTutorialResponseData({ ...data, tutorial_cover: updatedTutorialCover })
                        setYoutubeLink(data.youtube_channel)
                        setCustomLink(data.custom_domain)
                        setCustomLinkTitle(data.custom_domain_title)
                        setDiscordLink(data.discord_server)
                        setLinkedInLink(data.linkedin_link)
                        setGithubLink(data.github_link)
                        setTwitterLink(data.twitter_link)
                        setLoading(false)
                } catch (error) {
                        setLoading(false)
                        console.error("Error fetching walkthrough data.")
                }
        }

        // Add these functions to handle backend highlight operations

        // Function to save highlight to backend
        const saveHighlightToBackend = async (highlight) => {
                try {
                        const user = auth.currentUser;
                        const token = user ? await user.getIdToken() : null;
                        if (!token) {
                                console.error("User is not authenticated.");
                                return;
                        }
                        
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/documentation/highlights/${tutorialName}/${originalAuthorUid}/`, {
                                method: 'POST',
                                headers: {
                                        'requesting-user-uid': userUid,
                                        'requesting-user-email': userEmail,
                                        'auth-token': `Bearer ${token}`,
                                        'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                        document_name: `document_${currentDocumentIndex}`, // Or any identifier for the document
                                        highlighted_text: highlight.text,
                                        text_position: highlight.position || 0,
                                        color: highlight.color || "#ffff00" // Default yellow
                                }),
                        });
                        
                        if (!response.ok) {
                                throw new Error('Failed to save highlight');
                        }
                        
                        return await response.json();
                } catch (error) {
                        console.error("Error saving highlight:", error);
                }
        };
        
        // Function to fetch highlights from backend
        const fetchHighlightsFromBackend = async () => {
                try {
                        const user = auth.currentUser;
                        const token = user ? await user.getIdToken() : null;
                        if (!token) {
                                console.error("User is not authenticated.");
                                return [];
                        }
                        
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/documentation/highlights/${tutorialName}/${originalAuthorUid}/`, {
                                method: 'GET',
                                headers: {
                                        'requesting-user-uid': userUid,
                                        'requesting-user-email': userEmail,
                                        'auth-token': `Bearer ${token}`,
                                },
                        });
                        
                        if (!response.ok) {
                                if (response.status === 404) {
                                        return []; // No highlights found is OK
                                }
                                throw new Error('Failed to fetch highlights');
                        }
                        
                        const data = await response.json();
                        // Filter highlights for the current document
                        const documentHighlights = data.highlights.filter(h => 
                                h.document_name === `document_${currentDocumentIndex}`
                        ).map(h => ({
                                id: `${h.document_name}_${h.text_position}`, // Create a unique ID from position
                                text: h.highlighted_text,
                                position: h.text_position,
                                color: h.color
                        }));
                        
                        return documentHighlights;
                } catch (error) {
                        console.error("Error fetching highlights:", error);
                        return [];
                }
        };
        
        // Function to delete highlight from backend
        const deleteHighlightFromBackend = async (highlightId) => {
                try {
                        const user = auth.currentUser;
                        const token = user ? await user.getIdToken() : null;
                        if (!token) {
                                console.error("User is not authenticated.");
                                return;
                        }
                        
                        // Parse out document_name and text_position from the highlightId
                        // Assuming highlightId format: document_name_position
                        const highlightParts = highlightId.toString().split('_');
                        const documentName = highlightParts.slice(0, -1).join('_');
                        const textPosition = highlightParts[highlightParts.length - 1];
                        
                        // If highlightId doesn't follow our expected format, use defaults
                        const document_name = documentName || `document_${currentDocumentIndex}`;
                        const text_position = textPosition || 0;
                        
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/documentation/highlights/${tutorialName}/${originalAuthorUid}/`, {
                                method: 'DELETE',
                                headers: {
                                        'requesting-user-uid': userUid,
                                        'requesting-user-email': userEmail,
                                        'auth-token': `Bearer ${token}`,
                                        'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                        document_name: document_name,
                                        text_position: text_position
                                }),
                        });
                        
                        if (!response.ok) {
                                throw new Error('Failed to delete highlight');
                        }
                        
                        return true;
                } catch (error) {
                        console.error("Error deleting highlight:", error);
                        return false;
                }
        };

        const fetchDocumentsForWalkthrough = async () => {
                try {
                        const user = auth.currentUser;
                        const token = user ? await user.getIdToken() : null;
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/documentation/`, {
                                method: 'POST',
                                headers: {
                                        'requesting-user-uid': userUid,
                                        'requesting-user-email': userEmail,
                                        'auth-token': `Bearer ${token}`,
                                        'mode': 'tutorial',
                                        "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                        document_ids: tutorialResponseData.document_order,
                                }),
                        })
                        const data = await response.json()
                        return data;
                } catch (error) {
                        console.error("Error in fetching documents for walkthrough: ", error)
                }
        }

        const handleDeleteClick = async () => {
                const isConfirmed = window.confirm("Are you sure you want to delete this walkthrough?");
                if (!isConfirmed) {
                        return
                }
                try {
                        const user = auth.currentUser;
                        const token = user ? await user.getIdToken() : null;
                        if (!token) {
                                console.error("User is not authenticated.");
                                return;
                        }
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/walkthrough/delete/${tutorialName}/${originalAuthorUid}/`, {
                                method: 'DELETE',
                                headers: {
                                        'requesting-user-uid': userUid,
                                        'requesting-user-email': userEmail,
                                        'auth-token': `Bearer ${token}`,
                                        "Content-Type": "application/json",
                                }
                        })
                        if (response.ok) {
                                console.log("Walkthrough deleted successfully.")
                                navigate("/walkthroughs")
                        } else {
                                console.log("Failed to delete walkthrough")
                        }
                } catch (error) {
                        console.error("Error in deleting walkthrough: ", error)
                }
        }

        const formatTimestamp = (timestamp) => {
                const date = new Date(timestamp);
                return date.toLocaleString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                });
        };

        const transformContent = (htmlContent) => {
                if (!htmlContent) return "";
        
                const parser = new DOMParser();
                const doc = parser.parseFromString(htmlContent, "text/html");
        
                doc.querySelectorAll("h1").forEach((h1) => {
                        const h3 = document.createElement("h3");
                        h3.innerHTML = h1.innerHTML;
                        h1.replaceWith(h3);
                });
        
                doc.querySelectorAll("h2").forEach((h2) => {
                        const h4 = document.createElement("h4");
                        h4.innerHTML = h2.innerHTML;
                        h2.replaceWith(h4);
                });
        
                return doc.body.innerHTML;
        };         

        const printWalkthrough = async () => {
                // Show loading indicator
                const loadingDiv = document.createElement('div');
                loadingDiv.className = "DocumentationHomeComponent__loading-animation-div";
                loadingDiv.innerHTML = '<i class="fas fa-spinner fa-spin DocumentationHomeComponent__loading-animation-icon"></i><p>Preparing print view...</p>';
                document.body.appendChild(loadingDiv);
                
                try {
                  // Save original document index
                  const originalIndex = currentDocumentIndex;
                  
                  // Create a new window for printing
                  const printWindow = window.open('', '_blank');
                  
                  // Add styles
                  printWindow.document.write(`
                    <html>
                    <head>
                      <title>${tutorialName || 'Walkthrough'}</title>
                      <style>
                        body { 
                          font-family: Arial, sans-serif; 
                          margin: 30px; 
                          color: #333;
                          line-height: 1.6;
                        }
                        h1, h2, h3, h4 { 
                          margin-top: 20px; 
                          color: #2c3e50;
                        }
                        .page { 
                          display: block; 
                          page-break-after: always; 
                          margin-bottom: 30px;
                          padding-bottom: 30px;
                        }
                        img { max-width: 100%; height: auto; }
                        .highlighted-text { background-color: #ffeb3b; }
                        code { 
                          background: #f4f4f4; 
                          padding: 2px 4px; 
                          border-radius: 3px;
                          font-family: monospace;
                        }
                        pre { 
                          background: #f4f4f4; 
                          padding: 10px; 
                          border-radius: 5px; 
                          overflow-x: auto;
                        }
                        blockquote {
                          border-left: 4px solid #ccc;
                          margin-left: 0;
                          padding-left: 16px;
                          background-color: #f9f9f9;
                        }
                        .metadata {
                          color: #7f8c8d;
                          font-size: 0.9em;
                          margin-bottom: 15px;
                        }
                        .social-links {
                          margin: 15px 0;
                        }
                        .social-links i {
                          display: none; /* Hide icons in print */
                        }
                        table {
                          border-collapse: collapse;
                          width: 100%;
                          margin: 15px 0;
                        }
                        table, th, td {
                          border: 1px solid #ddd;
                        }
                        th, td {
                          padding: 8px;
                          text-align: left;
                        }
                        @media print {
                          .page { margin: 0; border: none; }
                          a { text-decoration: none; color: #000; }
                        }
                      </style>
                    </head>
                    <body>
                  `);
                  
                  // Add cover page
                  printWindow.document.write(`
                    <div class="page">
                      <h1>${tutorialName}</h1>
                      <div class="metadata">
                        <p><strong>By:</strong> ${userName}</p>
                        <p><strong>Views:</strong> ${tutorialResponseData.views}</p>
                      </div>
                      <p>${tutorialResponseData.description}</p>
                      <div class="social-links">
                  `);
                  
                  // Add social links
                  if (customLink) printWindow.document.write(`<p><strong>${customLinkTitle || 'Website'}:</strong> ${customLink}</p>`);
                  if (linkedInLink) printWindow.document.write(`<p><strong>LinkedIn:</strong> ${linkedInLink}</p>`);
                  if (githubLink) printWindow.document.write(`<p><strong>GitHub:</strong> ${githubLink}</p>`);
                  if (twitterLink) printWindow.document.write(`<p><strong>Twitter:</strong> ${twitterLink}</p>`);
                  if (discordLink) printWindow.document.write(`<p><strong>Discord:</strong> ${discordLink}</p>`);
                  if (youtubeLink) printWindow.document.write(`<p><strong>YouTube:</strong> ${youtubeLink}</p>`);
                  
                  printWindow.document.write(`
                      </div>
                      ${tutorialResponseData.tutorial_cover}
                    </div>
                  `);
                  
                  // Add document pages
                  for (let i = 0; i < selectedDocuments.length; i++) {
                    const doc = selectedDocuments[i];
                    printWindow.document.write(`
                      <div class="page">
                        <h2>${doc.name}</h2>
                        <div class="metadata">
                          <p><strong>Created:</strong> ${formatTimestamp(doc.created_at)}</p>
                          <p><strong>Updated:</strong> ${formatTimestamp(doc.updated_at)}</p>
                          <p><strong>Views:</strong> ${doc.views}</p>
                        </div>
                        ${doc.content}
                      </div>
                    `);
                  }
                  
                  printWindow.document.write(`
                      <div style="text-align: center; margin-top: 20px; color: #999; font-size: 12px;">
                        <p>Generated from Bluebird Documentation</p>
                      </div>
                    </body>
                    </html>
                  `);
                  
                  printWindow.document.close();
                  
                  // Wait for content to load then print
                  printWindow.onload = function() {
                    setTimeout(() => {
                      // Remove the loading indicator
                      document.body.removeChild(loadingDiv);
                      printWindow.print();
                    }, 1000);
                  };
                  
                  // Restore original index
                  setCurrentDocumentIndex(originalIndex);
                } catch (error) {
                  console.error("Error preparing print view:", error);
                  document.body.removeChild(loadingDiv);
                  alert("There was an error preparing the print view. Please try again.");
                }
              };
        
        function capitalizeFirstLetter(val) {
                return String(val).charAt(0).toUpperCase() + String(val).slice(1);
        }
        
        const handleHighlightManagerClick = () => {
                setIsHighlightManagerVisible(!isHighlightManagerVisible);
        }

        const handleShareClick = () => {
                setIsShareVisible(!isShareVisible)
        }

        const copyToClipboard = () => {
                navigator.clipboard.writeText(link);
                alert("Link copied to clipboard!");
        };

        const navigateToSocialMediaPage = (url) => () => {
                if (url) {
                        window.open(url, '_blank', 'noopener,noreferrer');
                } else {
                        console.warn("Invalid URL");
                }
        };

        const handleNextDocument = () => {
                if (currentDocumentIndex < selectedDocuments.length - 1) {
                        setCurrentDocumentIndex((prevIndex) => prevIndex + 1);
                }
        };
            
        const handlePreviousDocument = () => {
                if (currentDocumentIndex > 0) {
                        setCurrentDocumentIndex((prevIndex) => prevIndex - 1);
                }
        };            

        const handleSidebarToggle = useCallback((collapsed) => {
                setIsSidebarCollapsed(collapsed);
        }, []);

        const handleHomeButtonClick = () => { navigate("/") }
        const handleLoginButtonClick = () => { navigate("/login") }
        const handleRegisterButtonClick = () => { navigate("/register") }

        if (loading) {
                return (
                        <div className="DocumentationHomeComponent__loading-animation-div">
                                <i className="fas fa-spinner fa-spin DocumentationHomeComponent__loading-animation-icon"></i>
                        </div>
                )
        }

        if (loading === false && hasPermission === false) {
                return (
                        <div className="DocumentationMainPage__div">
                                {/* <Sidebar /> */}
                                <div className="DocumentationMainPage__main-content">
                                        <p className="large">It looks like you don't have permission to view this walkthrough.</p>
                                        <p className="medium">
                                                <strong>You might just need to sign in</strong>. If this is your walkthrough, make sure you're signed in. If 
                                                this issue persists, this could be a bug on our end. We are very sorry for the inconvenience; please let us know 
                                                this has happened.
                                        </p>
                                        <p className="medium">
                                                <strong>If this is not your walkthrough</strong>, check with the original author of the document to see if you have correct 
                                                permissions.
                                        </p>
                                        <button onClick={handleHomeButtonClick} style={{ marginRight: "10px" }} className="ConflictResolver__button">Home</button>
                                        <button onClick={handleLoginButtonClick} style={{ marginRight: "10px" }} className="ConflictResolver__button">Login</button>
                                        <button onClick={handleRegisterButtonClick} className="ConflictResolver__button">Register</button>
                                </div>
                        </div>
                )
        }

        if (loading === false && published === false) {
                return (
                        <div className="DocumentationMainPage__div">
                                <WalkthroughSidebar />
                                <div className="DocumentationMainPage__main-content">
                                        <p className="large">It looks you have permission to view this walkthrough, but is has not been published yet.</p>
                                        <p className="medium">
                                                <strong>As of the current version</strong>, only collaborators that have "Can Edit" permission can view/edit walkthroughs that 
                                                are not published yet. Otherwise, once the walkthrough is marked as published, you will be able to view the walkthrough.
                                        </p>
                                </div>
                        </div>
                )
        }

        return (
                <div className="DocumentationMainPage__div">

                        <WalkthroughSidebar onToggleSidebar={handleSidebarToggle} />

                        <div className="Document__floating-div">
                                <i className="fa-solid fa-highlighter Document__icon-padding WalkthroughPage__highlight-icon-glow" 
                                   title="Highlight Tips" 
                                   onClick={handleHighlightManagerClick} 
                                   style={{ marginRight: "8px", cursor: "pointer" }}></i>
                                <div className="Document__icon-separator"></div>
                                {tutorialResponseData.is_public ? (
                                        <i class="fa-solid fa-share Document__icon-padding" title="Share" onClick={handleShareClick} style={{ marginRight: "8px", cursor: "pointer" }}></i>
                                ) : (
                                        <></>
                                )}
                                {canEdit ? (
                                        <Link to={`/walkthroughs/${tutorialName}/${originalAuthorUid}/edit`} className="Document__a">
                                                <i className="fas fa-edit Document__icon-padding" title="Edit" style={{ marginRight: "8px", cursor: "pointer" }}></i>
                                        </Link>
                                ) : (
                                        <i className="fas fa-lock Document__icon-padding" title="Can't Edit" style={{ marginRight: "8px", cursor: "not-allowed" }}></i>
                                )}
                                   <i className="fa-solid fa-file-arrow-down Document__icon-padding" 
                                        onClick={printWalkthrough} 
                                        title="Print Walkthrough" 
                                        style={{ marginRight: "8px", cursor: "pointer" }}></i>
                                {originalAuthorUid === userUid ? (
                                        <i className="fas fa-trash Document__icon-padding" title="Delete Document" style={{ marginRight: "8px", cursor: "pointer" }} onClick={handleDeleteClick}></i>
                                ) : (
                                        <i className="fas fa-lock Document__icon-padding" title="Can't Delete Document" style={{ marginRight: "8px", cursor: "not-allowed" }}></i>
                                )}
                                <div className="Document__icon-separator"></div>
                                {tutorialResponseData.is_public ? (
                                        <i className="fas fa-eye Document__icon-padding" title="Document is Public" style={{ cursor: "pointer" }}></i>
                                ) : (
                                        <i className="fas fa-eye-slash Document__icon-padding" title="Document is not Public" style={{ cursor: "pointer" }}></i>
                                )}
                                {tutorialResponseData.completed ? (
                                        <i className="fa-solid fa-circle-check Document__icon-padding" title="Published" style={{ cursor: "pointer" }}></i>
                                ) : (
                                        <i className="fa-solid fa-circle-xmark Document__icon-padding" title="Not Published" style={{ cursor: "pointer" }}></i>
                                )}
                        </div>

                        {isShareVisible && (
                                <div className="Document__overlay">
                                        <div className="Document__popup">
                                                <h2>Share Walkthrough</h2>
                                                <p>To share a link of this walkthrough, you can copy the link below.</p>
                                                <div className="Document__shareable-link-container">
                                                        <p><span role="textbox" aria-label="Shareable link" className="Document__share-link">https://bluebird-documentation.com/walkthroughs/{tutorialName}/{originalAuthorUid}</span></p>
                                                        <i
                                                                className="fa-solid fa-copy Document__copy-icon"
                                                                onClick={copyToClipboard}
                                                                title="Copy to clipboard"
                                                        ></i>
                                                </div>
                                                <button className="Document__modal-close-button" onClick={handleShareClick}>Close</button>
                                        </div>
                                </div>
                        )}
                        {isHighlightManagerVisible && (
                                <div className="Document__overlay">
                                        <div className="Document__popup">
                                                <h2>Interact With Your Walkthrough!</h2>
                                                <p>We're constantly working to improve the interactivity of Walkthroughs!</p>
                                                <p>As of this current release, users like yourself can highlight documents in Walkthroughs.</p>
                                                <ul className="WalkthroughPage__popup-list">
                                                        <li>To highlight text, click and drag your cursor like you normally would to highlight things to copy them.</li>
                                                        <li>To remove highlights, just right click on them.</li>
                                                </ul>
                                                {/* Popup content will be added later */}
                                                <button className="Document__modal-close-button" onClick={handleHighlightManagerClick}>Close</button>
                                        </div>
                                </div>
                        )}

                        <div ref={pdfRef}>

                                {currentDocumentIndex === 0 ? (
                                        <div ref={walkthroughRef} className="WalkthroughPage__main-content">
                                                <h2>{tutorialName}</h2>

                                                <p className="medium WalkthroughPage__author-name">By: {userName}</p>

                                                <p className="small">Views: {tutorialResponseData.views}</p>

                                                <div className="WalkthroughPage__social-media-links">
                                                        {customLink ? (
                                                                <i className="fa-solid fa-link WalkthroughPage__icon-padding" title={customLinkTitle} style={{ cursor: "pointer" }} onClick={navigateToSocialMediaPage(customLink)}></i>
                                                        ) : (
                                                                <></>
                                                        )}
                                                        {linkedInLink ? (
                                                                <i className="fa-brands fa-linkedin WalkthroughPage__icon-padding" title="LinkedIn Profile Link" style={{ cursor: "pointer" }} onClick={navigateToSocialMediaPage(linkedInLink)}></i>
                                                        ) : (
                                                                <></>
                                                        )}
                                                        {githubLink ? (
                                                                <i className="fa-brands fa-square-github WalkthroughPage__icon-padding" title="GitHub Profile Link" style={{ cursor: "pointer" }} onClick={navigateToSocialMediaPage(githubLink)}></i>
                                                        ) : (
                                                                <></>
                                                        )}
                                                        {twitterLink ? (
                                                                <i className="fa-brands fa-square-x-twitter WalkthroughPage__icon-padding" title="Twitter Profile Link" style={{ cursor: "pointer" }} onClick={navigateToSocialMediaPage(twitterLink)}></i>
                                                        ) : (
                                                                <></>
                                                        )}
                                                        {discordLink ? (
                                                                <i className="fa-brands fa-discord WalkthroughPage__icon-padding" title="Discord Server Link" style={{ cursor: "pointer" }} onClick={navigateToSocialMediaPage(discordLink)}></i>
                                                        ) : (
                                                                <></>
                                                        )}
                                                        {youtubeLink ? (
                                                                <i className="fa-brands fa-youtube WalkthroughPage__icon-padding" title="YouTube Channel Link" style={{ cursor: "pointer" }} onClick={navigateToSocialMediaPage(youtubeLink)}></i>
                                                        ) : (
                                                                <></>
                                                        )}
                                                </div>

                                                <p className="small">{tutorialResponseData.description}</p>

                                                <div className="ql-editor ql-editor-styling WalkthroughPage__tutorial-cover" dangerouslySetInnerHTML={{ __html: tutorialResponseData.tutorial_cover }} />
                                        </div>
                                ) : (
                                        <div className="DocumentationMainPage__main-content">
                                                <div className="Document__div">
                                                        <h2>{selectedDocuments[currentDocumentIndex - 1]?.name}</h2>
                                                        <p className="extra-small WalkthroughPage__views-text"><span className="semi-bold">Views: </span> <i>{selectedDocuments[currentDocumentIndex - 1]?.views}</i></p>
                                                        <p className="extra-small" style={{ paddingTop: "30px" }}><span className="semi-bold">Created At:</span> <i>{formatTimestamp(selectedDocuments[currentDocumentIndex - 1]?.created_at)}</i></p>
                                                        <p className="extra-small" style={{ paddingBottom: "30px" }}><span className="semi-bold">Last Updated At:</span> <i>{formatTimestamp(selectedDocuments[currentDocumentIndex - 1]?.updated_at)}</i></p>
                                                        <div 
                                                                className="ql-editor ql-editor-styling" 
                                                                onMouseUp={handleTextHighlight}
                                                                dangerouslySetInnerHTML={{ __html: selectedDocuments[currentDocumentIndex - 1]?.content }} 
                                                        />
                                                </div>
                                        </div>
                                )}

                                {currentDocumentIndex > 0 && (
                                        <button
                                                className="WalkthroughPage__prev-button"
                                                onClick={handlePreviousDocument}
                                                style={{ left: isSidebarCollapsed ? '260px' : '20px' }}
                                        >
                                                <i class="fa-solid fa-arrow-left"></i>
                                        </button>
                                )}

                                {currentDocumentIndex < selectedDocuments.length - 1 && (
                                        <button
                                                className="WalkthroughPage__next-button"
                                                onClick={handleNextDocument}
                                        >
                                                <i class="fa-solid fa-arrow-right"></i>
                                        </button>
                                )}

                        </div>

                </div>
        )
}

export default WalkthroughPage
