// React and Router imports
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Firebase imports
import { auth } from "../../firebase";

// Component imports
import DocumentationHomeComponent from "./DocumentationHomeComponent";
import Sidebar from "../shared/Sidebar";

// Styles
import "../../css/DocumentationMainPage.css"
import "../../css/Create.css"

function DocumentationMainPage() {

        const [uid, setUid] = useState(null)

        const navigate = useNavigate()

        useEffect(() => {
                const unsubscribe = auth.onAuthStateChanged((user) => {
                        if (user) {
                                setUid(user.uid)
                        } else {
                                setUid(null)
                        }
                })

                return () => { unsubscribe() }
        }, [])

        useEffect(() => {
                const theme = localStorage.getItem('theme') || 'Default';
                const root = document.documentElement;
        
                if (theme === 'Dark Theme') {
                        root.style.setProperty('--font-color', '#5B92E5')
                        root.style.setProperty('--bg-color', '#121d2d')
                }

                if (theme === 'Purple Theme') {
                        root.style.setProperty('--font-color', '#190019')
                        root.style.setProperty('--bg-color', '#f2e5f2')
                }
        }, []);

        const handleHomeButtonClick = () => { navigate("/") }
        const handleLoginButtonClick = () => { navigate("/login") }
        const handleRegisterButtonClick = () => { navigate("/register") }

        if (!uid) {
                return (
                        <div className="DocumentationMainPage__div">
                                {/* <Sidebar /> */}
                                <div className="DocumentationMainPage__main-content">
                                        <p className="large">It looks like you're not signed in.</p>
                                        <p className="medium">
                                                <strong>To create a document, you must have an account with us</strong>. If you already have an account, you can login by clicking the Login button 
                                                below. If you don't already have an account, you can create a new one by clicking the Register button below.
                                        </p>
                                        <button onClick={handleHomeButtonClick} style={{ marginRight: "10px" }} className="ConflictResolver__button">Home</button>
                                        <button onClick={handleLoginButtonClick} style={{ marginRight: "10px" }} className="ConflictResolver__button">Login</button>
                                        <button onClick={handleRegisterButtonClick} className="ConflictResolver__button">Register</button>
                                </div>
                        </div>
                )
        }

        return (
                <div className="DocumentationMainPage__div">
                        <Sidebar />

                        <div className="DocumentationMainPage__main-content">
                                <DocumentationHomeComponent />
                        </div>
                </div>
        )

}

export default DocumentationMainPage
