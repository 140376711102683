// React and Router imports
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"

// Firebase imports
import { auth } from "../../firebase.js"

// Component imports
import WalkthroughSidebar from "./WalkthroughSidebar"

// Styles
import "../../css/Walkthrough.css"

function Walkthrough() {

        const [loading, setLoading] = useState(true)
        const [uid, setUid] = useState("")
        const [userEmail, setUserEmail] = useState("")
        const navigate = useNavigate()
        const [hasTutorials, setHasTutorials] = useState(false)

        useEffect(() => {
                const unsubscribe = auth.onAuthStateChanged((user) => {
                        if (user) {
                                setUid(user.uid)
                                setUserEmail(user.email)
                        } else {
                                setUid(null)
                                setUserEmail(null)
                        }
                })
                return () => { unsubscribe() }
        }, [])

        useEffect(() => {
                const theme = localStorage.getItem('theme') || 'Default';
                const root = document.documentElement;
        
                if (theme === 'Dark Theme') {
                        root.style.setProperty('--bg-color', '#121d2d')
                        root.style.setProperty('--bg-main-container', "#121d2d")
                        root.style.setProperty('--font-color', "#5B92E5")
                }

                if (theme === 'Purple Theme') {
                        root.style.setProperty('--bg-color', '#f2e5f2')
                        root.style.setProperty('--font-color', "#190019")
                        root.style.setProperty('--bg-main-container', "#f2e5f2")
                }
        }, []);

        useEffect(() => { if (uid) { fetchTutorialDetails() } }, [uid])

        const fetchTutorialDetails = async () => {
                try {
                        const user = auth.currentUser;
                        const token = user ? await user.getIdToken() : null;
                
                        if (!token) {
                                console.error("User is not authenticated.");
                                return;
                        }

                        if (uid.length !== 0) {
                                const response = await fetch("https://bluebirddocumentationadmin.pythonanywhere.com/walkthrough/" + uid + "/", {
                                        method: "GET",
                                        headers: {
                                                "auth-token": `Bearer ${token}`,
                                                'requesting-user-uid': uid,
                                                'requesting-user-email': userEmail,
                                        }
                                })

                                if (response.status === 200) {
                                        console.log("Successfully fetched tutorial data.")
                                        setHasTutorials(true)
                                        setLoading(false)
                                } else if (response.status === 404) {
                                        console.log("No tutorials found for this author.")
                                        setHasTutorials(false)
                                        setLoading(false)
                                } else {
                                        console.log("Failed to fetch tutorials for this author.")
                                }
                        }
                } catch (error) {
                        console.error("Error in fetching tutorials for this author: ", error)
                }
        }

        const handleCreateClick = () => { navigate("/walkthrough/create") }

        return (
                <div className="Walkthrough__div">
                        <WalkthroughSidebar />

                        <div className="Walkthrough__main-content">
                                {loading ? (
                                        <div className="DocumentationHomeComponent__loading-animation-div">
                                                <i className="fas fa-spinner fa-spin DocumentationHomeComponent__loading-animation-icon"></i>
                                        </div>
                                ) : loading === false && hasTutorials === false ? (
                                        <div>
                                                <p className="large">
                                                        Looks like you haven't created any walkthroughs yet! <strong>Click the button below to get started</strong>.
                                                </p>
                                                <button onClick={handleCreateClick} className="submit-button">Create</button>
                                        </div> 
                                ) : (
                                        <div>
                                                <p className="large">
                                                        <strong>To view/modify existing walkthroughs</strong>, click on any of the walkthroughs on the left sidebar.
                                                </p>
                                                <p className="large" style={{ paddingTop: "50px" }}>
                                                        <strong>To create a new walkthrough</strong>, click the green "Create" button on the bottom of the left sidebar.
                                                </p>
                                        </div>
                                )}
                        </div>
                </div>
        )

}

export default Walkthrough