// React and Router imports
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// Firebase imports
import { auth } from "../../firebase.js";

// Styles imports
import "../../css/DocumentationHomeComponent.css";
import "../../css/Sidebar.css";
import "../../css/WalkthroughSidebar.css";

function WalkthroughSidebar({ onToggleSidebar }) {

        const [collapsed, setCollapsed] = useState(() => {
                return window.innerWidth > 600;
        });
        const [tutorialDetails, setTutorialDetails] = useState([])
        const [sharedTutorialDetails, setSharedTutorialDetails] = useState([])
        const [loading, setLoading] = useState(true)
        const [uid, setUid] = useState("")
        const [userEmail, setUserEmail] = useState("")
        const [hasTutorials, setHasTutorials] = useState(true)
        const [viewingSharedTutorials, setViewingSharedTutorials] = useState(false)
        const [hasSharedTutorials, setHasSharedTutorials] = useState(true)
        const navigate = useNavigate()

        useEffect(() => {
                window.addEventListener("resize", handleResize);
                return () => {
                        window.removeEventListener("resize", handleResize);
                };
        }, []);

        useEffect(() => {
                if (onToggleSidebar) {
                        onToggleSidebar(collapsed);
                }
        }, [collapsed, onToggleSidebar]);

        useEffect(() => {
                const theme = localStorage.getItem('theme') || 'Default';
                const root = document.documentElement;
                if (theme === 'Dark Theme') {
                        root.style.setProperty('--a-color', '#5B92E5')
                        root.style.setProperty('--icon-font-color', '#5B92E5')
                        root.style.setProperty('--icon-color', '#5B92E5')
                        root.style.setProperty('--a-hover-color', '#3f66a0')
                        root.style.setProperty('--bg-btn-search', '#3f66a0')
                        root.style.setProperty('--btn-color', '#090e16')
                        root.style.setProperty('--help-search-color', '#3f66a0')
                }
                if (theme === 'Purple Theme') {
                        root.style.setProperty('--a-color', '#f2e5f2')
                        root.style.setProperty('--icon-font-color', '#f2e5f2')
                        root.style.setProperty('--icon-color', '#f2e5f2')
                        root.style.setProperty('--a-hover-color', '#cc99cc')
                        root.style.setProperty('--bg-btn-search', '#a64ca6')
                        root.style.setProperty('--btn-color', '#f2e5f2')
                        root.style.setProperty('--help-search-color', '#f2e5f2')
                        root.style.setProperty('--bg-sidebar-color', '#190019')
                }
        }, []);

        useEffect(() => {
                const unsubscribe = auth.onAuthStateChanged((user) => {
                if (user) {
                        setUid(user.uid);
                        setUserEmail(user.email)
                } else {
                        setUid(null);
                        setUserEmail(null)
                }
                });
                return () => { unsubscribe(); };
        }, []);

        useEffect(() => {
                const fetchTutorialDetails = async () => {
                        if (!uid) return;
                        try {
                                const user = auth.currentUser;
                                const token = user ? await user.getIdToken() : null;
                                if (!token) {
                                        console.error("User is not authenticated.");
                                        return;
                                }
                                const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/walkthrough/${uid}/`, {
                                        method: "GET",
                                        headers: {
                                                'requesting-user-uid': uid,
                                                'requesting-user-email': userEmail,
                                                "auth-token": `Bearer ${token}`,
                                        }
                                })
                                if (response.status === 404) {
                                        setHasTutorials(false)
                                        setLoading(false)
                                        return
                                }
                                const data = await response.json()
                                setLoading(false)
                                setTutorialDetails(data);
                                setHasTutorials(true)
                        } catch (error) {
                                console.error("Error fetching page details: ", error)
                        }
                }
                fetchTutorialDetails()
        }, [uid])

        const toggleSidebar = () => {
                setCollapsed((prev) => {
                        const newState = !prev;
                        if (onToggleSidebar) {
                                onToggleSidebar(newState);
                        }
                        return newState;
                });
        };

        const handleResize = () => {
                const shouldCollapse = window.innerWidth > 600;
                setCollapsed(shouldCollapse);
                if (onToggleSidebar) {
                        onToggleSidebar(shouldCollapse);
                }
        };

        const fetchSharedTutorials = async () => {
                if (!uid) return
                try {
                        const user = auth.currentUser;
                        const token = user ? await user.getIdToken() : null;
                        if (!token) {
                                console.error("User is not authenticated.");
                                return;
                        }
                        const response = await fetch(`https://bluebirddocumentationadmin.pythonanywhere.com/walkthrough/shared-walkthroughs/`, {
                                method: "GET",
                                headers: {
                                        'requesting-user-uid': uid,
                                        'requesting-user-email': userEmail,
                                        "auth-token": `Bearer ${token}`,
                                }
                        })
                        if (response.status === 404) {
                                setHasSharedTutorials(false)
                                return
                        }
                        const data = await response.json()
                        const isEmpty = Array.isArray(data) ? data.length === 0 : Object.keys(data).length === 0
                        if (isEmpty) {
                                setHasSharedTutorials(false)
                                return
                        }
                        setHasSharedTutorials(true)
                        setSharedTutorialDetails(data)
                } catch (error) {
                        console.error("Error fetching shared walkthroughs: ", error)
                }
        }

        const handleUsersIconClick = () => {
                const theme = localStorage.getItem('theme') || 'Default';
                if (theme === "Default") {
                        const usersIcon = document.getElementById("users-icon")
                        usersIcon.style.borderBottom = "1px solid white"
                } else if (theme === "Dark Theme") {
                        const usersIcon = document.getElementById("users-icon")
                        usersIcon.style.borderBottom = "1px solid #5B92E5"
                }
                const userIcon = document.getElementById("user-icon")
                userIcon.style.borderBottom = "1px solid #090E16"
                setViewingSharedTutorials(true)
                fetchSharedTutorials()
        }

        const handleUserIconClick = () => {
                const theme = localStorage.getItem('theme') || 'Default';
                if (theme === "Default") {
                        const userIcon = document.getElementById("user-icon")
                        userIcon.style.borderBottom = "1px solid white"
                } else if (theme === "Dark Theme") {
                        const userIcon = document.getElementById("user-icon")
                        userIcon.style.borderBottom = "1px solid #5B92E5"
                }
                const usersIcon = document.getElementById("users-icon")
                usersIcon.style.borderBottom = "1px solid #090E16"
                setViewingSharedTutorials(false)
        }

        const handleHelpClick = () => { navigate("/help") }
        // const toggleSidebar = () => { setCollapsed(!collapsed) }
        const handleNavigateClick = () => { navigate("/walkthroughs/create") }
        // const handleResize = () => { setCollapsed(window.innerWidth > 600) }

        return (
                <div className={`sidebar ${collapsed ? 'collapsed': ''}`}>
                        <button onClick={toggleSidebar} className="toggle-button">
                                <i className={`fas ${collapsed ? 'fa-arrow-left' : 'fa-arrow-right'}`}></i>
                        </button>
                        <div className={`Sidebar__icon-container ${collapsed ? 'Sidebar__icon-container': 'Sidebar__hide-content'}`}>
                                <Link to="/">
                                        <i className="fas fa-home Sidebar__icons" title="Home" style={{ marginRight: "25px" }}></i>
                                </Link>
                                <i id="user-icon" onClick={handleUserIconClick} className="fa-solid fa-user Sidebar__icons Sidebar__underline" title="View your walkthroughs." style={{ marginRight: "25px" }}></i>
                                <i id="users-icon" onClick={handleUsersIconClick} className="fa-solid fa-users Sidebar__icons" title="View walkthroughs shared with you."></i>
                        </div>
                        {loading ? (
                                <div>Loading...</div>
                        ) : (
                                <nav className={`${collapsed ? '': 'Sidebar__hide-content'}`}>
                                        {hasTutorials && viewingSharedTutorials !== true ? (
                                                <ul className="Sidebar__ul">
                                                        {tutorialDetails.map((tutorial, index) => (
                                                                <li key={index}>
                                                                        <Link key={`${tutorial.tutorial_name}`} to={`/walkthroughs/${tutorial.tutorial_name}/${uid}`}>{tutorial.tutorial_name}</Link>
                                                                </li>
                                                        ))}
                                                </ul>
                                        ) : hasSharedTutorials && viewingSharedTutorials ? (
                                                <ul className="Sidebar__ul">
                                                        {sharedTutorialDetails.map((item, index) => (
                                                                <li key={index}>
                                                                        <Link key={`${item.tutorial_name}`} to={`https://bluebird-documentation.com/${item.tutorial_url}`}>{item.tutorial_name}</Link>
                                                                </li>
                                                        ))}
                                                </ul>
                                        ) : viewingSharedTutorials && hasSharedTutorials !== true ? (
                                                <p className="Sidebar__no-content" style={{ padding: "10px", fontSize: "16px" }}>No shared walkthroughs to show.</p>
                                        ): (
                                                <p className="Sidebar__no-content" style={{ padding: "10px", fontSize: "16px" }}>No walkthroughs to show.</p>
                                        )}
                                </nav>
                        )}
                        {collapsed && (
                                <>
                                        <button className="Sidebar__create-button" onClick={handleNavigateClick}>
                                                <i className="fas fa-plus Sidebar__plus-sign"></i>&nbsp; Create
                                        </button>
                                        <i class="fa-solid fa-circle-question Sidebar__help" onClick={handleHelpClick} title="Help"></i>
                                </>
                        )}
                </div>
        )

}

export default WalkthroughSidebar