// React and Router imports
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Firebase related imports
import { useAuthState } from "react-firebase-hooks/auth";
import {
        auth,
        registerWithEmailAndPassword,
        showNotification,
} from "../../firebase.js";

// Styles
import "../../css/Auth.css"

function Register() {

        const [email, setEmail] = useState("")
        const [password, setPassword] = useState("")
        const [name, setName] = useState("")
        const [uid, setUid] = useState("")
        const [adLevel, setAdLevel] = useState("")
        const [user, loading, error] = useAuthState(auth)
        const navigate = useNavigate()

        const handleSelectedAdLevel = (e) => { setAdLevel(e.target.value) }

        const register = async () => {
                if (!name) {
                        showNotification("Please enter name")
                } else {
                        const newUserId = await registerWithEmailAndPassword(name, email, password)
                        setUid(newUserId)

                        const data = {
                                email: email,
                                name: name,
                                userId: newUserId,
                                adLevel: adLevel,
                        }

                        try {
                                const response = await fetch("https://bluebirddocumentationadmin.pythonanywhere.com/users/create/", {
                                        method: "POST",
                                        headers: { "Content-Type": "application/json"},
                                        body: JSON.stringify(data)
                                })

                                if (response.ok) {
                                        console.log("Successfully saved user data to database.")
                                } else {
                                        console.log("Failed to submit user data to backend.")
                                }
                        } catch (error) {
                                console.error("Error in submitting data: ", error)
                        }
                }
        }

        const handleSubmit = (e) => {
                e.preventDefault();
                register();
        };

        useEffect(() => {
                if (loading) {
                        return
                }
                if (user) {
                        navigate("/")
                }
        }, [user, loading])

        return (
                <div 
                        className="container-fluid"
                        style={{
                                padding: "0",
                                minHeight: "100vh",
                                backgroundColor: "#6b9ce7"
                        }}
                >

                        <div className="notification"></div>

                        <div className="row" style={{ minHeight: "100vh" }}>

                                <div className="col-12 d-flex justify-content-center align-items-center">

                                        <div className="Auth__box">
                                                <h1 className="Auth__text-center">Register</h1>
                                                <form onSubmit={handleSubmit}>
                                                        <div className="Auth__input-container">
                                                                <input
                                                                        type="text"
                                                                        value={name}
                                                                        onChange={(e) => setName(e.target.value)}
                                                                        required
                                                                />
                                                                <label>Name</label>
                                                        </div>
                                                        <div className="Auth__input-container">
                                                                <input
                                                                        type="email"
                                                                        value={email}
                                                                        onChange={(e) => setEmail(e.target.value)}
                                                                        required
                                                                />
                                                                <label>E-mail Address</label>
                                                        </div>
                                                        <div className="Auth__input-container">
                                                                <input
                                                                        type="password"
                                                                        value={password}
                                                                        onChange={(e) => setPassword(e.target.value)}
                                                                        required
                                                                />
                                                                <label>Password</label>
                                                        </div>
                                                        <button
                                                                type="submit"
                                                                className="Auth__btn"
                                                        >
                                                                Register
                                                        </button>
                                                </form>
                                                
                                                <div style={{ paddingTop: "30px", color: "white" }}>
                                                        Already have an account? <Link to="/login">Login</Link> now.
                                                </div>
                                        </div>

                                </div>

                        </div>

                </div>
        )

}

export default Register